import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sanitize } from 'dompurify';
import HelpIcon from '@mui/icons-material/Help';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Autocomplete,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';
import { ReactComponent as Filter } from '@assets/icon_filter.svg';
import { useUpdateEffect, useIdle } from 'react-use';

import { getFormattedSignOffDate } from '@utilities/dateHelpers';
import { colors } from '@ais/theme';
import { DRAG_TYPES, FIELD_TYPES, FORM_SETTINGS, WAITING_TIME } from '@ais/constants';
import {
  INTERNAL_CONTROLS_FORM_TYPE_ID,
  SCHEMA_ACTION_TYPES
} from '@ais/constants';
import { FORM_NAME, TRENDING_BY_MONTH, KPIS_RATIOS } from '@constants/forms';
import { TRIAL_BALANCE, PROJECT_FORM_INSTANCE } from '@constants/index';
import { QUESTION_LINK_TYPE } from '@ais/constants';
import { useGETProjectClientHeaderInformationByProjectId } from '@services/project';
import { useTitle } from '@hooks/useTitle';
import {
  VFSection,
  VFSignOff,
} from '@components/FormView';

import { VFRenderedField } from './components/VFRenderedField';

import { SchemaContext } from '@ais/providers';
import { CLADialogConfirm, CLALinkPanel, CLASimpleDrawer, CLATooltip } from "@ais/components";
import { CLASnackbarWithContent } from "@ais/components";
import { useFormInstanceConnection } from '@hooks/ProjectFormInstance/index';

import projectFormServices, { useSaveProjectUnitAnswer } from '@services/forms/projectforms.js';
import { useFinalizedProject } from '@hooks/useProject';
import useUserProviderState from "@contexts/UserContext";
import useProjectContext from "@contexts/ProjectContext"
import formServices from '@services/forms/forms.js';
import { useLoading } from '@hooks/index';
import CLADialogForm from '@components/CLADialogForm/CLADialogForm';
import { useCustomHistory } from '@hooks/index';
import GraphsManager from '@views/PlanningAnalytics/components/Graphs/GraphsManager';
import update from 'immutability-helper';
import styles from './InternalControlForm.module.css';
import { VFCanvasProvider, useProjectFormInstanceProvider } from '../../providers';
import { TailorProceduresDrawerContext } from '@contexts/TailorProcedures/TailorProceduresDrawerContext';
import { useKeyControlDrawerContext } from '@contexts/KeyControlDrawer/KeyControlDrawerContext'
import { Flex } from "@ais/palette"
import ClientAccessModal from '@views/ProjectFormInstance/components/ClientAccess/ClientAccessModal';
import KeyControlDrawer from './components/Sections/UnderstandingOfEntity/KeyControlDrawer/KeyControlDrawer.jsx';
import { ShareFormButton } from '@components/ShareForm';
import { Controller, FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { ProcedureContextProvider } from '@contexts/Procedures';
import { ProjectRiskDeficienciesProvider } from '@contexts/ProjectRiskDeficienciesContext';
import CustomToast from '@components/CustomToast/CustomToast';
import { useQueryClient } from "@tanstack/react-query"
import ConcurrentUsersBanner from './ConcurrentUsersBanner';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

const InternalControlForm = ({ projectForm }) => {
  const MODAL_DELETE = PROJECT_FORM_INSTANCE.EN.MODAL.DELETE;
  const MODAL_CONFLICT_RESOLUTION = PROJECT_FORM_INSTANCE.EN.MODAL.CONFLICT_RESOLUTION;
  const BUTTONS = PROJECT_FORM_INSTANCE.EN.BUTTONS;
  const CONFIRMATION_TYPE = PROJECT_FORM_INSTANCE.EN.CONFIRMATION_TYPE;
  const { SHAREABLE_FORMS, CLIENT_ACCESS_MODAL } = FORM_SETTINGS.EN;
  const { MODE } = CLIENT_ACCESS_MODAL;

  const navigate = useNavigate();
  const { projectId, projectFormId } = useParams();
  const isProjectFinalized = useFinalizedProject(projectId);
  const { showProcedureDrawer, setShowProcedureDrawer } = useContext(TailorProceduresDrawerContext);
  const { project } = useProjectContext()
  const { isOpen: isKCDOpen } = useKeyControlDrawerContext();

  const { lastPage, setLastPage } = useCustomHistory();

  const { schema, dispatchSchema } = useContext(SchemaContext);
  const [methodologyIndustries, setMethodologyIndustries] = useState([]);
  const [methodologyVersionId, setMethodologyVersionId] = useState(null);
  const [units, setUnits] = useState([]);
  const [hasConflicts, setHasConflicts] = useState(false);
  const [schemaLoading, setSchemaLoading] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [questionWithLinksLoading, setQuestionWithLinksLoading] = useState(false);
  const [projectFormInstance, setProjectFormInstance] = useState(null);
  const [questionsWithLinks, setQuestionsWithLinks] = useState([]);
  const [sectionIdInEditMode, setSectionIdInEditMode] = useState();
  const [confirmModal, setConfirmModal] = useState({ isOpen: false });
  const [showDrawer, setShowDrawer] = useState(false);
  const [relatedFields, setRelatedFields] = useState([]);
  const [procedureFilter, setProcedureFilter] = useState({});
  const [showConflictResolveLink, setShowConflictResolveLink] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [hasSelectedDominantUnit, setHasSelectedDominantUnit] = useState(false);
  const [formModal, setFormModal] = useState({
    isOpen: false,
    data: {}
  });
  const [frameworks, setFrameworks] = useState([])
  const [performanceStandards, setPerformanceStandards] = useState([])
  const saveProjectUnitAnswerHook = useSaveProjectUnitAnswer();
  const { mutateAsync: saveProjectUnitAnswer, isLoading, isSuccess: saveProjectUnitAnswerSuccess, isError: saveProjectUnitAnswerError } = saveProjectUnitAnswerHook
  const { getUser } = useUserProviderState();
  const {
    signOffList,
    answerList,
    unitAnswerList,
    modifiedAfterSignOff,
    auditAreaScotabdRisk,
    formTypeId,
    actions: { 
      updateUnitIds,
      riskAssessmentIdentifyModifiedAnswerAfterSignOff
    },
    setStates: {
      setSignOffList,
      setAnswerList,
      setUnitAnswerList,
      setFormTypeId,
      setModifiedAfterSignOff
    }
  } = useProjectFormInstanceProvider();
  const [userId, setUserId] = useState('');
  const [projectFormUsageModal, setProjectFormUsageModal] = useState([]);
  const [lockedQuestionField, setLockedQuestionField] = useState(null);
  const [answeredQuestionField, setAnsweredQuestionField] = useState({});
  const [shareClientModalVisble, setShareClientModalVisble] = useState({ visible: false, mode: "" })
  const [sharedToClient, setSharedToClient] = useState(!(projectForm && projectForm.clientProjectFormStatusId === null))
  const setLoading = useLoading();
  const isIdle = useIdle(WAITING_TIME.MINS_13);
  const queryClient = useQueryClient();

  const {
    data: userPrefProjects
  } = useGETProjectClientHeaderInformationByProjectId(projectId);
  
  const title = userPrefProjects?.data?.AccountNumber ? `${userPrefProjects?.data?.AccountNumber} > ${projectFormInstance?.projectFormName}` : undefined;
  useTitle(title);

  const sortSignOffListAsc = (list) =>
    list.sort((a, b) =>
      new Date(a.signOffDate).getTime() > new Date(b.signOffDate).getTime()
        ? 1
        : -1
    );

  const identifyModifiedQuestionsAfterSignOff = useCallback(async (newUnitValue) => {
    let answerResult = await projectFormServices.getAnswersByProjectId(projectFormId, projectId);
    answerResult = answerResult.filter((val, index, arr) => arr.findIndex(obj => obj.questionId === val.questionId && (obj.answer !== null || obj.answer !== undefined)) === index);

    const newUnitAnswerList =
      (typeof (newUnitValue) === 'object' && Object.entries(newUnitValue).length) && answerResult.length ?
        [...answerResult, newUnitValue]
        : answerResult;

    setUnitAnswerList(newUnitAnswerList || []);
    const answers = newUnitAnswerList?.reduce((map, obj) => {
      map[obj.questionId.toLowerCase()] = obj.answer;
      return map;
    }, {});
    setAnswerList(answers);

    const signOffResult = await projectFormServices.getSignOffByProjectFormId(projectFormId, projectId);
    const sortSignOff = sortSignOffListAsc(signOffResult || []);

    let lastSignOffDate = null;
    if (sortSignOff && sortSignOff?.length > 0) {
      const reviewerSignOffs = sortSignOff.filter(
        (signOff) => signOff.signOffLevel !== 1
      );
      lastSignOffDate =
        getFormattedSignOffDate(reviewerSignOffs[reviewerSignOffs?.length - 1]?.signOffDate);
    }
    if (newUnitAnswerList && newUnitAnswerList?.length > 0) {
      const modifiedList = lastSignOffDate
        ? newUnitAnswerList
          .filter(
            (answer) =>
              answer.lastUpdate &&
              new Date(getFormattedSignOffDate(lastSignOffDate)).getTime() <
              new Date(answer.lastUpdate).getTime() &&
              isSignoffLogicEnabled(isProjectFinalized, new Date(answer.lastUpdate).getTime(), true)
          )
          ?.map((answer) => answer.questionId)
        : [];
      setModifiedAfterSignOff(modifiedList);
    }
  }, [signOffList]);

  const getAnswersAndSignOffList = async () => {
    if (projectId && projectFormId) {
      setAnswerLoading(true);
      try {
        let answerResult = await projectFormServices.getAnswersByProjectId(
          projectFormId,
          projectId
        );
        answerResult = answerResult.filter((val, index, arr) => arr.findIndex(obj => obj.questionId === val.questionId && obj.answer !== "") === index);
        setUnitAnswerList(answerResult || []);
        const answers = answerResult?.reduce((map, obj) => {
          map[obj.questionId.toLowerCase()] = obj.answer;
          return map;
        }, {});
        setAnswerList(answers || []);
        const signOffResult =
          await projectFormServices.getSignOffByProjectFormId(
            projectFormId, projectId);
        const sortSignOff = sortSignOffListAsc(signOffResult || []);
        setSignOffList(sortSignOff);
        identifyModifiedQuestionsAfterSignOff();
        setAnswerLoading(false);
      } catch (error) {
        toast.error(error.toString());
        setAnswerLoading(false);
      }
    }
  };

  const getQuestionsWithLink = async () => {
    if (projectFormId) {
      setQuestionWithLinksLoading(true);
      try {
        const result = await projectFormServices.getQuestionsWithLink(
          projectId,
          projectFormId
        );
        if (result) {
          setQuestionsWithLinks(result);
        }
        setQuestionWithLinksLoading(false);
      } catch (error) {
        toast.error(error.toString());
        setQuestionWithLinksLoading(false);
      }
    }
  };

  const initConflictData = () => {
    getAnswersAndSignOffList();
    getQuestionsWithLink();
  }

  const validationSchema = Yup.object({
    unitlist: Yup.string().required()
  })
  const defaultValues = {
    unitlist: '',
  }
  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });
  const onSubmit = async values => {
    try {
      setLoading(true);
      await projectFormServices.updateDominantUnit(
        projectId,
        projectFormId,
        values.unitlist
      );

      setFormModal({
        isOpen: false,
        title: MODAL_CONFLICT_RESOLUTION.HEADER,
        cancelText: BUTTONS.BACK_TO_DASHBOARD,
        submitText: BUTTONS.SAVE,
        message: MODAL_CONFLICT_RESOLUTION.MESSAGE,
      });
 
      initConflictData();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.toString());
    }
  }

  useEffect(() => {
    async function initData() {
      const user = await getUser();
      const userLocalId = user?.activeDirectoryId ?? '';

      setUserId(userLocalId);
    }

    initData()
  }, [])

  const handleProjectFormUsageModal = useCallback((projectFormUsages) => {
    setProjectFormUsageModal(projectFormUsages);
  }, [projectFormUsageModal])

  // For locking without calling broadcastData
  // Temporary change to make broadcasting work
  const _handleQuestionField = useCallback((data, isIdle = false) => {
    setLockedQuestionField((prevState) =>
      prevState?.questionId !== data?.questionId
        ? {
            ...data,
          }
        : {
            ...prevState,
          }
    );
  }, []);  

  const resetAnswerQuestionField = useCallback(questionId => {
    setAnsweredQuestionField(prev => update(prev, { [`${questionId}`]: { $set: undefined } }))
  }, []);

  const handleAnsweredQuestionField = useCallback((data) => {
    const answerType = typeof data?.answer === 'string'
    const answer = answerType ? data.answer : (data?.answer || null)
    setAnsweredQuestionField(
      prevState => data?.prevQuestionId ? (
        prevState[`${data.prevQuestionId}`] ?
          update(prevState, { [`${data.prevQuestionId}`]: { $set: answer } }) :
          update(prevState, { $merge: { [`${data.prevQuestionId}`]: answer } })
      ) :
        prevState)
  }, [projectFormUsageModal])

  const handleQuestionField = (data, isIdle = false) => {
    _handleQuestionField(data, isIdle);
  } 
  
  const deleteQuestion = async () => {
    setLoading(true);
    const response = await projectFormServices.deleteInstanceQuestionById(
      projectId,
      projectFormId,
      confirmModal.data?.selectedId
    );
    if (response.status === 200) {
      const newSection = response.data.section;
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS,
        index: confirmModal.data?.selectedSectionIndex,
        payload: newSection.fields,
      });
    }
    setLoading(false);
  };

  const handleModalConfirm = useCallback(async () => {
    setConfirmModal((prev) => ({ ...prev, isOpen: false }));
    if (confirmModal.data) {
      switch (confirmModal.action) {
        case CONFIRMATION_TYPE.DELETE_QUESTION:
          await deleteQuestion();
          break;
        default:
      }
    }
  }, [confirmModal]);

  const handleModalCancel = useCallback(() => {
    setConfirmModal((prev) => ({ ...prev, isOpen: false }));
  }, [confirmModal]);

  const modifyProjectFormData = (projectForm) => {
    projectForm?.schema.forEach((item) => {
      if (item.id.includes(TRIAL_BALANCE.EN.ID.toUpperCase())) {
        item.fields[0].forEach((field) => {
          if (
            field.type ===
            TRIAL_BALANCE.EN.ID.split('-').join('_').toUpperCase()
          ) {
            field.required =
              projectForm.projectFormName === FORM_NAME.PLANNING_ANALYTICS;
          }
        });
      }
    });
    return projectForm;
  };

  const getSchema = async () => {
    if (projectFormId) {
      setSchemaLoading(true);
      try {
        if (projectForm) {
          setProjectFormInstance(modifyProjectFormData(projectForm));
          setUnits(projectForm.units);
          const formData = await formServices.getFormById(projectForm?.formID);
          setFormTypeId(formData?.formTypeId);
          setMethodologyVersionId(formData?.methodologyVersionId);
          setMethodologyIndustries(
            projectForm.methodologyIndustries?.map(
              (mi) => mi.MethodologyIndustryId
            )
          );
          setMethodologyVersionId(projectForm.methodologyVersionId);
          const payload = {
            formSettings: null,
            properties: projectForm.schema,
            disabledDeleteQuestionIds: [],
            formTypeId: formData?.formTypeId
          };
          setFrameworks(
            projectForm.frameworks?.map(
              (f) => f.FrameworkId
            )
          );
          setPerformanceStandards(
            projectForm.performanceStandards?.map(
              (ps) => ps.PerformanceStandardId
            )
          );
          dispatchSchema({
            type: SCHEMA_ACTION_TYPES.INITIALIZE_SCHEMA,
            payload,
          });

          const linkDefaultList = projectForm.schema.flatMap(section => section)?.flatMap(row => row.fields)?.flatMap(field => field)?.flatMap(field => (
            {
              id: field.id,
              isUserModified: !!field.isUserModified,
              existingQuestionId: field.existingQuestionId,
              linkType: field.linkType
            }
          ))?.filter(field => field.linkType === QUESTION_LINK_TYPE.QUESTION_LINK_TYPES[2]);
          if (linkDefaultList?.length)
            dispatchSchema({
              type: SCHEMA_ACTION_TYPES.INITIALIZE_ANSWER_LINK_DEFAULT_IDS,
              payload: linkDefaultList ?? [],
            });
        }
      } catch (error) {
        toast.error(error.toString());
      }
      setSchemaLoading(false);
    }
  };

  const hasConflictAnswers = async () => {

    const data = await projectFormServices.hasConflictAnswers(
      projectFormId, projectId
    );

    if (data?.HasConflicts) {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        setShowConflictResolveLink(true);
        return;
      }

      setHasConflicts(true);
      return;
    }

    setShowConflictResolveLink(false);
  };

  useEffect(() => {
    const init = async () => {
      await getSchema();
    };
    init();
  }, []);

  useUpdateEffect(() => { 
    const hasDominantUnit = units?.some((unit) => !!unit.IsDominantUnit);
    if (units?.length === 1) {
      setHasConflicts(false);
      setHasSelectedDominantUnit(true);
    } else if (units?.length > 1 && hasDominantUnit) {
      const selectedUnit = units?.find(unit => unit.IsDominantUnit);

      formInstance.setValue('unitlist', selectedUnit.ProjectUnitID.toString());

      setHasConflicts(true);
      setHasSelectedDominantUnit(true);
    } else {
      hasConflictAnswers();
    } 
  }, [units]);

  useEffect(() => {  
    if (hasConflicts) {
      setShowConflictResolveLink(true);
    }
    else {
      initConflictData();
      setShowConflictResolveLink(false);
    }
  }, [hasConflicts]);

  useEffect(() => {
    setLoading(answerLoading || schemaLoading || questionWithLinksLoading);
  }, [answerLoading, schemaLoading, questionWithLinksLoading]);

  const formIsPlanningAnalytics = formTypeId && formTypeId === 3;

  const showSubheading =
    showConflictResolveLink || (projectFormInstance?.formDescription && !formIsPlanningAnalytics);

  const handleEditMenuClick = (sectionId) => {
    setSectionIdInEditMode(sectionId);
  };

  const handleUpdateSignOffList = useCallback((signOffList) => {
    const sortSignOff = sortSignOffListAsc(signOffList);
    setSignOffList(sortSignOff);
    identifyModifiedQuestionsAfterSignOff();
    riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
  }, [unitAnswerList]);

  const handleClickAway = (sectionId) => {
    if (sectionIdInEditMode === sectionId && !confirmModal.isOpen) {
      setSectionIdInEditMode('');
    }
  };

  const closeDrawer = () => {
    setShowDrawer(false);
    setRelatedFields([]);
  };

  const handleDeleteQuestion = (selectedQuestionId, sectionIndex) => {
    setConfirmModal({
      isOpen: true,
      title: MODAL_DELETE.HEADER,
      cancelText: BUTTONS.CANCEL,
      confirmText: BUTTONS.DELETE,
      message: MODAL_DELETE.DELETE_MESSAGE,
      data: {
        selectedId: selectedQuestionId,
        selectedSectionIndex: sectionIndex,
      },
      action: CONFIRMATION_TYPE.DELETE_QUESTION,
    });
  };

  const setUserModifiedAnswerLinkDefault = (id, isUserModified) => {
    const foundIndex = schema.answerLinkDefaultIds?.findIndex(field => field.id === id);

    if (!foundIndex || foundIndex < 0) return;

    dispatchSchema({
      type: SCHEMA_ACTION_TYPES.UPDATE_ANSWER_LINK_DEFAULT_IDS,
      payload: isUserModified,
      index: foundIndex
    });
  }
  const saveAnswer = async (id, value) => {
    let projectUnits = projectFormInstance ? projectFormInstance?.units : modifyProjectFormData(projectForm)?.units;
    const isDefaultLinkModified = schema?.answerLinkDefaultIds?.length > 0 ? schema.answerLinkDefaultIds.findIndex(c => c.id === id && !c.isUserModified) >= 0 : false;
    if (isDefaultLinkModified) {
      return;
    }
    const projectUnitAnswer = {
      projectId: projectId,
      projectUnitIds: projectUnits?.map(
        (item) => item.ProjectUnitID
      ) || [],
      questionId: id,
      answer: value,
    };
    const response = await saveProjectUnitAnswer({
      projectId,
      projectFormId,
      unitAnswer: projectUnitAnswer
    });
    if (response.status === 204) {
      queryClient.invalidateQueries('projectFormAnswers');
      if (!hasSelectedDominantUnit) {
        hasConflictAnswers(null);
      }

      if (!!questionsWithLinks?.length) {
        identifyModifiedQuestionsAfterSignOff();
      } else {
        const newUnitValue = {
          projectId: projectId,
          questionId: id,
          answer: value,
          lastUpdate: getFormattedSignOffDate()
        };
        const newUnitAnswerList = unitAnswerList.length
          ? [...unitAnswerList, newUnitValue]
          : [newUnitValue];
        setUnitAnswerList(newUnitAnswerList);
        identifyModifiedQuestionsAfterSignOff(newUnitValue);
        setAnswerList((prev) => {
          return { ...prev, [id]: value };
        });
      }
    }
    else {
      toast.error(PROJECT_FORM_INSTANCE.EN.ERROR_IN_SAVING_ANSWER)
    }
  }

  const displayRelatedFields = async (questionId) => {
    const { data } = await projectFormServices.getRelatedFieldsByQuestionsIds(
      projectId,
      questionId
    );
    if (data) {
      const relatedFields = data.map((item) => ({
        formId: item.projectFormId,
        formName: item.projectFormName,
        questionIds: item.questionIds,
        sectionId: item.sectionId,
        sectionName: item.sectionName,
      }));
      setRelatedFields(relatedFields);
      setShowDrawer(true);
    }
  };

  const openConflictDialog = () => {
    setFormModal({
      isOpen: true,
      title: MODAL_CONFLICT_RESOLUTION.HEADER,
      cancelText: BUTTONS.BACK_TO_DASHBOARD,
      submitText: BUTTONS.SAVE,
      message: MODAL_CONFLICT_RESOLUTION.MESSAGE,
    });
  }

  const handleOnFocus = (data, isIdle = false) => {
    handleQuestionField(data, isIdle);
  }

  const handleOnShareClient = () => {
    setShareClientModalVisble({ visible: true, mode: MODE.SHARE })
  }

  const handleOnRemoveShareClient = () => {
    setShareClientModalVisble({ visible: true, mode: MODE.REMOVE_SHARE })
  }

  useEffect(() => {
    updateUnitIds(projectFormInstance?.units?.map(unit => unit.ProjectUnitID));
  }, [projectFormInstance?.units]);

  const filterCurrentUserId = (data, userId) => {
    return data.filter(e => e.userId !== userId && userId.length > 0);
  }
  const getCurrentUserLockDetails = (data, userId) => {
    return data.filter(e => e.userId === userId && userId.length > 0);
  }

  const renderRenderField = ({ row, rowIndex, prop, index }) => {
    return row?.map((field, fieldIndex) => (
      <VFRenderedField
        key={`col-${fieldIndex}`}
        units={units}
        field={field}
        isEditMode={sectionIdInEditMode === prop.id}
        projectFormId={projectFormId}
        section={{ ...prop, index }}
        sectionIndex={index}
        rowIndex={rowIndex}
        onDeleteQuestion={handleDeleteQuestion}
        onLinkClick={
          questionsWithLinks && questionsWithLinks.includes(field.id)
            ? displayRelatedFields
            : null
        }
        answerList={answerList}
        modifiedAfterSignOff={modifiedAfterSignOff}
        projectId={projectId}
        methodologyIndustries={methodologyIndustries}
        methodologyVersionId={methodologyVersionId}
        formTypeId={formTypeId}
        lockDetails={{ ...filterCurrentUserId(projectFormUsageModal, userId).find(form => form.questionId === field.id) }}
        currentUserlockDetails={{ ...getCurrentUserLockDetails(projectFormUsageModal, userId).find(form => form.questionId === field.id) }}
        handleOnFocus={handleOnFocus}
        handleQuestionField={handleQuestionField}
        answeredQuestionField={answeredQuestionField[`${field.id}`]}
        procedureFilter={procedureFilter[`${prop.id}`]}
        isInstance
        disabled={isProjectFinalized}
        setUserModifiedAnswerLinkDefault={setUserModifiedAnswerLinkDefault}
        project={project}
        answered={projectForm && projectForm.clientProjectFormStatusId === 3}
        resetAnswerQuestionField={resetAnswerQuestionField}
        signOffList={signOffList}
        frameworks={frameworks}
        performanceStandards={performanceStandards}
      />
    ));
  };

  const renderHeaderRight = (prop) => {
    if (prop.title === TRENDING_BY_MONTH.title) {
      return (
        <GraphsManager
          projectId={projectId}
          projectFormId={projectFormId}
          sectionId={prop.id}
          schema={schema}
          questionId={prop?.fields[0]?.filter((field) => field.type === FIELD_TYPES.RADIOGROUP)[0]?.id}
          disabled={isProjectFinalized}
        />)
    }

    return undefined;
  }


  return (

    <div className={styles['project-form-instance']}>
      <Grid container gap="29px">
        {projectFormInstance?.units && (
          <Grid container gap="8px" wrap="nowrap">
            <Grid item>
              <div className={styles['vf-units-label']}>
                {PROJECT_FORM_INSTANCE.EN.ASSIGNED_UNITS}
              </div>
            </Grid>
            <Grid>
              <div className={styles['vf-units']}>
                {projectFormInstance?.units
                  .map((item) => item.ProjectUnitTitle)
                  .join(' | ')}
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container gap="8px">
          <Grid item xs={12}>
            <Flex direction="row" justify="between">
              <div className={styles['vf-form-title']}>
                {projectFormInstance?.projectFormName}
              </div>
              <ShareFormButton
                isInternalControlForm
                isSharedToClient={sharedToClient} 
                isProjectFinalized={isProjectFinalized}
                projectForm={projectFormInstance}
                onRemoveSharedClientClick={handleOnRemoveShareClient}
                onShareClientClick={handleOnShareClient}
              />
            </Flex>

          </Grid>
          {projectFormInstance?.toolTipText && (
            <Grid>
              <CLATooltip
                title={
                  <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(projectFormInstance?.toolTipText, {
                        ADD_ATTR: ['target'],
                      }),
                    }}
                  />
                }
                placement="bottom"
              >
                <HelpIcon
                  style={{ fontSize: '18px', color: colors.navy[400] }}
                />
              </CLATooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      {
        showSubheading && (
          <div className={styles['vf-form-description']}>
            <span>{formIsPlanningAnalytics ? '' : projectFormInstance?.formDescription}</span>
            {showConflictResolveLink ? (
              <span onClick={openConflictDialog} className={`${styles['vf-form-description-conflict']} ${isProjectFinalized ? styles['disable-click'] : ''}`}>
                Conflict Resolution
              </span>
            ) : (
              <></>
            )}
          </div>
        )
      }

      <ProjectRiskDeficienciesProvider>
        <ProcedureContextProvider>
          <VFCanvasProvider
            schema={schema}
            answerList={answerList}
            onSaveAnswer={saveAnswer}
            isInstance
          >
            {answerLoading ? <></> : (
              <React.Fragment>
                {schema?.properties?.map((prop, index) =>
                  prop.type === DRAG_TYPES.SECTION_BLANK ?
                    (prop?.fields?.map((row, rowIndex) =>
                      renderRenderField({ row, rowIndex, prop, index })
                    ))
                    : (
                      prop.title === KPIS_RATIOS.title ? <></> :
                        <VFSection
                          key={index}
                          section={{ ...prop, index }}
                          projectId={projectId}
                          projectFormId={projectFormId}
                          formTypeId={formTypeId}
                          isInstance
                          onEditMenuClick={handleEditMenuClick}
                          onBlur={handleClickAway}
                          listenEvent={!confirmModal.isOpen}
                          methodologyIndustries={methodologyIndustries}
                          formName={projectFormInstance?.projectFormName}
                          headerRight={() => { return renderHeaderRight(prop) }}
                          disabled={isProjectFinalized}
                          units={projectFormInstance?.units}
                          type={prop.type}
                          frameworks={frameworks}
                          performanceStandards={performanceStandards}
                        >
                          <div className={styles['vf-section-inner']}>
                            {prop?.fields?.map((row, rowIndex) => (
                              <div
                                className={styles['row-field']}
                                key={`row-${rowIndex}`}
                              >
                                {renderRenderField({
                                  row,
                                  rowIndex,
                                  prop,
                                  index
                                })}
                              </div>
                            ))}
                          </div>
                        </VFSection>
                    ))}
              </React.Fragment>
            ) }
          </VFCanvasProvider>
        </ProcedureContextProvider>
      </ProjectRiskDeficienciesProvider>

      {projectFormInstance && (
        <VFSignOff
          isInstance
          requiredSignOffLevel={projectFormInstance?.requiredSignOffLevel}
          projectFormId={projectFormId}
          signOffList={signOffList}
          updateSignOffList={handleUpdateSignOffList}
          disabled={isProjectFinalized}
        />
      )}
      <CLADialogConfirm
        visible={confirmModal.isOpen}
        title={confirmModal.title}
        cancelText={confirmModal.cancelText}
        confirmText={confirmModal.confirmText}
        message={confirmModal.message}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
      <FormProvider {...formInstance}>
        <CLADialogForm
          onSubmit={onSubmit}
          visible={formModal.isOpen}
          title={formModal.title}
          cancelText={formModal.cancelText}
          submitText={formModal.submitText}
          message={formModal.message}
          onCancel={() => {
            const copy = lastPage;
            setLastPage('');
            if (
              !copy ||
              copy.includes('/project-dashboard') ||
              navigate.length > 1
            )
              navigate(`/project-dashboard/${projectId}`);
            else navigate(copy);
          }}
        >
          <div style={{ marginTop: '24px', marginBottom: '25px' }}>
            <FormControl
              fullWidth
              sx={{ flexDirection: 'row', alignItems: 'center', gap: '12px' }}
            >
              <Controller
                control={formInstance.control}
                name="unitlist"
                render={({ field: { onChange, value } }) => {
                  return (
                    <RadioGroup
                      name="unitlist"
                      value={value}
                      onChange={onChange}
                      column
                      sx={{
                        gap: '20px',
                        width: '100%',
                        '& label': {
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          '&:not(:last-child)': {
                            borderBottom: '0.5px solid #909090',
                          },
                        },
                      }}
                      disabled={isProjectFinalized}
                    >
                      {projectFormInstance?.units.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          label={option.ProjectUnitTitle}
                          value={option.ProjectUnitID}
                          control={<Radio />}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '14px',
                              color: '#565656',
                            },
                          }}
                        />
                      ))}
                    </RadioGroup>
                  )
                }}
              />
            </FormControl>
          </div>
        </CLADialogForm>
      </FormProvider>
      <CLASimpleDrawer
        anchor="right"
        isVisible={showDrawer}
        onClose={closeDrawer}
        allowBackDropCloseClick
      >
        <CLALinkPanel
          fields={relatedFields}
          title="Linked Fields"
          projectId={projectId}
          isInstance
          disabled={isProjectFinalized}
        />
      </CLASimpleDrawer>
      <KeyControlDrawer isVisible={isKCDOpen} isProjectFinalized={isProjectFinalized} />
      <ConcurrentUsersBanner />
      {shareClientModalVisble.visible &&
        <ClientAccessModal
          visible={shareClientModalVisble}
          setShareClientModalVisble={setShareClientModalVisble}
          projectForm={projectForm}
          projectId={projectId}
          setSharedToClient={setSharedToClient} />}

      <CustomToast 
        error={saveProjectUnitAnswerError} 
        success={saveProjectUnitAnswerSuccess} 
        loading={isLoading}
      />
    </div>
  );
};

export default InternalControlForm;
