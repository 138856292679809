import clsx from "clsx"
import { useCallback, useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from "react-hook-form"
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Button, IconButton, SwipeableDrawer, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Flex, Label, Form } from "@ais/palette"
import { TbSelect } from "./TbSelect";
import { useTrialBalanceData } from "./useTrialBalanceData"
import { CLADropdown, CLARadioButtonList } from "@ais/forms";
import styles from "./TbLinkDrawer.module.css"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useFinalizedProject } from '@hooks/useProject';

const TYPE_MENU_ITEMS = [
    {
        value: "account",
        label: "Type"
    },
    {
        value: "accountSubType",
        label: "Classification"
    },
    {
        value: "fsCaption",
        label: "Category"
    },
    {
        value: "accountName",
        label: "Sub Category"
    },
    {
        value: "glAccountNumber",
        label: "Account"
    }
]

const FUND_LEVEL_MENU_ITEMS = [
    {
        value: "none",
        label: "None"
    },
    {
        value: "fundType",
        label: "Fund Type"
    },
    {
        value: "fundSubType",
        label: "Fund SubType"
    },
    {
        value: "fund",
        label: "Fund"
    }
]

const schema = Yup.object({
    correlationNameIds: Yup.array().required().min(1),
    type: Yup.string().required(),
    groupAccount: Yup.string().required(),
    period: Yup.string().required(),
    balanceType: Yup.string().required(),
    signOptions: Yup.string().required()
})

export const TbLinkDrawer = (props) => {

    const { open, setOpen, onSave, criteria, disabled, onCancel } = props
    const { projectId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const [isCleared, setIsCleared] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const defaultValues = {
        correlationNameIds: [],
        type: "",
        groupAccount: "",
        period: "",
        balanceType: "",
        fundLevel: "",
        fundIndex: "",
        signOptions: "Actual Sign"
    }

    const formMethods = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    })

    const selectedTrialBalances = useWatch({ control: formMethods.control, name: "correlationNameIds" })
    const selectedType = useWatch({ control: formMethods.control, name: "type" })
    const selectedFundLevel = useWatch({ control: formMethods.control, name: "fundLevel" })
    const selectedFundIndex = useWatch({ control: formMethods.control, name: "fundIndex" })
    const selectedBalanceType = useWatch({ control: formMethods.control, name: "balanceType" })
    const selectedGroupAccount = useWatch({ control: formMethods.control, name: "groupAccount" })
    const selectedPeriod = useWatch({ control: formMethods.control, name: "period" })

    const [groupAccountOptions,
        balanceTypes,
        recentPeriods,
        fundIndexes,
        foundIndexes,
        getTotalAmount] = useTrialBalanceData(selectedTrialBalances, selectedType, selectedFundLevel, selectedPeriod, projectId)

    const isFormDirty = () => {
        return formMethods.formState.isDirty;
    }

    useEffect(() => {
        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        if (!criteria) {
            return
        }
        formMethods.setValue('correlationNameIds', criteria.correlationNameIds ?? criteria?.trialBalances);
        formMethods.setValue('type', criteria?.type);
        formMethods.setValue('groupAccount', criteria?.groupAccount);
        formMethods.setValue('period', criteria?.period);
        formMethods.setValue('correlationNameIds', criteria.correlationNameIds ?? criteria?.trialBalances);
        formMethods.setValue('balanceType', criteria?.balanceType);
        formMethods.setValue('fundLevel', criteria?.fundLevel);
        formMethods.setValue('fundIndex', criteria?.fundIndex);
        formMethods.setValue('signOptions', criteria?.signOptions);
    }, [criteria])

    useEffect(() => {
        if (selectedFundLevel === "none" && selectedFundIndex) {
            formMethods.resetField("fundIndex")
        }
    }, [selectedFundLevel])

    useEffect(() => {
        if (selectedTrialBalances?.length < 1 && isFormDirty()) {
            formMethods.reset(defaultValues)
        }
    }, [selectedTrialBalances])

    useEffect(() => {
        if (selectedPeriod?.length && isFormDirty() && !recentPeriods.find(e => e.value === selectedPeriod)) {
            formMethods.resetField('period')
        }
    }, [recentPeriods])

    useEffect(() => {
        if (balanceTypes?.length && isFormDirty() && !balanceTypes.find(e => e.value === selectedBalanceType)) {
            formMethods.resetField('balanceType')
        }
    }, [balanceTypes])

    useEffect(() => {
        if (selectedGroupAccount?.length && isFormDirty() && !groupAccountOptions.find(e => e.value === selectedGroupAccount)) {
            formMethods.resetField('groupAccount')
        }
    }, [groupAccountOptions])

    const handleCancel = useCallback(() => {
        setOpen(false);
        formMethods.reset();
        onCancel();
    }, [formMethods, setOpen])

    const handleSave = useCallback(async (event) => {
        const isValid = await formMethods.trigger();
        const formValues = formMethods.getValues();      
        formValues.amountEnding = getTotalAmount(formValues);
        if (isMounted) {
        onSave(formValues, isValid);
        setOpen(false);
        setIsCleared(false);
        }
    }, [formMethods, onSave, setOpen, setIsCleared]);

    const handleRemove = useCallback(() => {
        formMethods.setValue('correlationNameIds', []);
        setIsCleared(true);
    }, []);

    useEffect(() => {
        if (formMethods.formState.isSubmitSuccessful) {
            formMethods.reset()
        }
    }, [formMethods.formState, formMethods.submittedData, formMethods.reset]);

    const isDisabled = !criteria?.correlationNameIds?.length > 0 || isProjectFinalized || disabled;

    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => {
                setOpen(false);
                setIsCleared(false);
            }}
            onOpen={() => setOpen(true)}
            sx={{
                width: "50%",
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: "50%",
                    backgroundColor: "#E4E0E0"
                }
            }}
        >
            <Form form={formMethods}>
                <Flex className={styles["drawer__close-button"]}>
                    <Flex direction="unset" className={styles["drawer__content__icon-container"]}>
                        <IconButton
                            variant="close-drawer-button"
                            onClick={handleCancel}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Flex>
                </Flex>

                <Flex className={styles["drawer__container"]}>
                    <Label className={styles["drawer__label"]} size="md" weight="medium">Link Trial Balance Answer</Label>

                    <Flex className={styles["drawer__content"]}>
                        <Flex direction="row" className={styles["drawer__content__actions"]}>
                            <div 
                              data-testid="LinkOffIcon" 
                              onClick={isDisabled ? null : handleRemove} 
                              className={`${styles.linkOffIcon} ${isDisabled ? styles.disabled : ''}`}
                            >
                            <LinkOffIcon />
                            <Typography variant="caption">
                                   Remove TB Link
                            </Typography>
                            </div>
                            <Button variant="outlined" onClick={handleCancel} className={styles["drawer__content__cancel-button"]}>Cancel</Button>
                            <Button variant="contained" type="button" onClick={handleSave} disabled={(!isCleared && (!formMethods.formState.isDirty || !formMethods.formState.isValid || isProjectFinalized || disabled)) || (isCleared && (isProjectFinalized || disabled))} className={styles["drawer__content__submit-button"]}>Save</Button>
                        </Flex>

                        <Controller
                            control={formMethods.control}
                            name="correlationNameIds"
                            render={({ field: { onChange, value } }) => {
                                return <TbSelect value={value} onChange={onChange} defaultValues={value} isDisabled={isProjectFinalized || disabled} />
                            }}
                        />

                        <Flex className={styles["drawer__content__row"]} direction="row">
                            <Controller
                                control={formMethods.control}
                                name="type"
                                render={({ field: { onChange, value } }) => {
                                    return <CLADropdown label="Type" options={TYPE_MENU_ITEMS} value={value} onChange={onChange} isDisabled={isProjectFinalized || disabled} />
                                }}
                            />

                            <Controller
                                control={formMethods.control}
                                name="groupAccount"
                                render={({ field: { onChange, value } }) => {
                                    return <CLADropdown label="Group / Account" options={groupAccountOptions || []} value={value} onChange={onChange} isDisabled={isProjectFinalized || disabled} />
                                }}
                            />

                        </Flex>

                        <Flex className={styles["drawer__content__row"]} direction="row">
                            <Controller
                                control={formMethods.control}
                                name="period"
                                render={({ field: { onChange, value } }) => {
                                    return <CLADropdown label="Period" options={recentPeriods || []} value={value} onChange={onChange} isDisabled={isProjectFinalized || disabled} />
                                }}
                            />

                            <Controller
                                control={formMethods.control}
                                name="balanceType"
                                render={({ field: { onChange, value } }) => {
                                    return <CLADropdown label="Balance Type" options={balanceTypes || []} value={value} onChange={onChange} isDisabled={isProjectFinalized || disabled} />
                                }}
                            />

                        </Flex>

                        <Flex className={styles["drawer__content__row"]} direction="row">
                            <Controller
                                control={formMethods.control}
                                name="fundLevel"
                                render={({ field: { onChange, value } }) => {
                                    const _disabled = selectedType === "glAccountNumber" || !foundIndexes || isProjectFinalized || disabled
                                    return (
                                        <CLADropdown
                                            label="Fund Level"
                                            isDisabled={_disabled}
                                            value={value}
                                            onChange={onChange}
                                            options={!foundIndexes ? [] : FUND_LEVEL_MENU_ITEMS}
                                        />
                                    )
                                }}
                            />

                            <Controller
                                control={formMethods.control}
                                name="fundIndex"
                                render={({ field: { onChange, value } }) => {
                                    const _disabled = selectedType === "glAccountNumber" || !selectedFundLevel || selectedFundLevel === "none" || fundIndexes?.length < 1 || isProjectFinalized || disabled
                                    return (
                                        <CLADropdown
                                            label="Fund Index"
                                            isDisabled={_disabled}
                                            options={fundIndexes || []}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />

                        </Flex>

                        <Flex className={clsx(styles["drawer__content__row"], styles["drawer__content__sign-options"])} direction="column">
                            <Controller
                                control={formMethods.control}
                                name="signOptions"
                                render={({ field: { onChange, value } }) => {
                                    return <CLARadioButtonList columns={2} label="Sign Options" options={["Actual Sign", "Reverse Sign"]} value={value} onChange={onChange} isDisabled={isProjectFinalized || disabled} />
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Form>

        </SwipeableDrawer>
    )
}