import clientApi from '@utilities/claApiClient';
import * as Constants from '@constants/index';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import logger from '@utilities/logService';

const getProjectByProjectId = async (projectId) => {
    try {
        const response = await clientApi.get(`/projects/${projectId}`);
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const getProjects = async (projectState, userId, clientAccountId) => {
    const params = {
        projectstate: projectState,
        clientAccountId
    };

    const projects = await clientApi.get(`/projects/accessible/${userId}`, { params });

    return projects;
};

const getProjectsByClientId = async (clientId) => {
    try {
        const response = await clientApi.get(`/projects/client/${clientId}`);
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const getProjectClientTeamByProjectId = async (projectId, appDataInstanceId) => {
    const response = await clientApi.get(`/projectteams/${projectId}/clientteammembers`,
        { params: { appDataInstanceId: appDataInstanceId } }
    );
    return { data: response.data };
}

const createProject = async (reqBody) => {
    try {
        const response = await clientApi.post('/projects', reqBody);
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

export const getNewProjectHeaderInformationByProjectId = async (projectId) => {
    try {
        const response = await clientApi.get(`/projects/${projectId}/client/`);

        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const getProjectMethodologyVersionsByProjectId = (projectId) => {
    return Constants.METHODOLOGY_VERSIONS.filter(_ => _.projectId === projectId);
}

// KEY DELIVERABLES
const getKeyDeliverablesByProjectId = async (projectId) => {
    try {
        const response = await clientApi.get(`/deliverables/${projectId}`)
        return { status: 200, data: response.data }
    } catch (ex) {
        return { status: 400, message: ex }
    }
}

const getProjectReportKeyDeliverables = async (projectId) => {
    try {
        const response = await clientApi.get(`/projectreportdeliverables/${projectId}`)
        return { status: 200, data: response.data }
    } catch (ex) {
        return { status: 400, message: ex }
    }
}

const deleteKeyDeliverablesByProjectId = async (projectId, deliverableIDList) => {
    const body = { data: deliverableIDList }
    try {
        const response = await clientApi.delete(`/deliverables/${projectId}`, body)
        return { status: 200, data: response.data }
    } catch (ex) {
        return { status: 400, message: ex }
    }
}

const postKeyDeliverablesByProjectId = async (projectId, body) => {
    const response = await clientApi.post(`/deliverables/${projectId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'POST: Key deliverable response: ' + response }
}

const putKeyDeliverablesByProjectId = async (projectId, body) => {
    const response = await clientApi.put(`/deliverables/${projectId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'POST: Key deliverable response: ' + response }
}

const applyDefaultDeliverables = async (body) => {
    try {
        await clientApi.post('/deliverables/defaultdeliverables', body)
        return { status: 201 }
    } catch (error) {
        return { status: 400, message: error };
    }
}

// PROJECT UNITS
const getProjectUnits = async (projectId) => {
    const response = await clientApi.get(`/projects/${projectId}/units`)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'GET: Project units response: ' + response }
}

// Project Teams
const getTeamMembersDataByProjectId = async (projectId) => {
    try {
        const { data } = await clientApi.get('/projectteams/' + projectId)
        return { status: 200, data }
    } catch (ex) {
        return { status: 400, message: ex }
    }
}

const getProjectSimple = async (projectId) => {
    const response = await clientApi.get(`/projects/${projectId}/simple`)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'POST: Key deliverable response: ' + response }
}

// PROJECT REPORTS
const getProjectReportsByProjectId = async (projectId) => {
    try {
        const response = await clientApi.get(`/projectreports/${projectId}`)
        return { status: 200, data: response.data }
    } catch (exception) {
        return { status: 400, message: exception }
    }
}

const postProjectReportsByProjectId = async (projectId, body) => {
    try {
        const response = await clientApi.post(`/projectreports/${projectId}`, body)
        return { status: 201, data: response.data }
    } catch (exception) {
        return { status: 400, message: exception }
    }
}

const putProjectReportsByProjectId = async (projectId, body) => {
    try {
        await clientApi.put(`/projectreports/${projectId}`, body)
        return { status: 204 }
    } catch (exception) {
        return { status: 400, message: exception }
    }
}

const getProjectById = async projectId => {
    try {
        const response = await clientApi.get(`/projects/${projectId}`)
        return { status: 200, data: response.data }
    } catch (exception) {
        return { status: 500, message: exception }
    }
}

const getProjectReportUnits = async (projectId) => {
    try {
        const projectReportUnit = await clientApi.get(`/projects/${projectId}/reports/units`)

        return projectReportUnit;
    }
    catch (error) {
        logger.error(error)
    }
}

const fetchProjectReportsUnits = async (projectId) => {
    try {
        const { data } = await getProjectReportUnits(projectId);
        const reportUnits = data.filter(function (a) {
            var key = a.ProjectUnitId + '|' + a.ProjectReportId;
            if (!this[key]) {
                this[key] = true;
                return true;
            }
        }, Object.create(null));

        return reportUnits
    }
    catch (error) {
        logger.error(error)
    }
}

export const fetchProjectAuthorization = async (projectId, emailAddress) => {
    const response = await clientApi.get(`/projects/${projectId}/authorize?emailAddress=${emailAddress}`)
    return response
}

const projectServices = {
    createProject,
    getProjectByProjectId,
    getProjects,
    getProjectsByClientId,
    getNewProjectHeaderInformationByProjectId,
    getProjectMethodologyVersionsByProjectId,
    getKeyDeliverablesByProjectId,
    deleteKeyDeliverablesByProjectId,
    postKeyDeliverablesByProjectId,
    putKeyDeliverablesByProjectId,
    applyDefaultDeliverables,   
    getProjectUnits,
    getTeamMembersDataByProjectId,
    getProjectSimple,
    getProjectReportsByProjectId,
    postProjectReportsByProjectId,
    putProjectReportsByProjectId,
    getProjectById,
    getProjectReportUnits,
    getProjectClientTeamByProjectId
};

export default projectServices;

export const PROJECT_QUERY_KEYS = {
    PROJECT: ["PROJECT"],
    PROJECT_TEAM_MEMBER: (projectId) => [...PROJECT_QUERY_KEYS.PROJECT, "PROJECT_TEAM_MEMBER", projectId],
    PROJECT_SIMPLE: (projectId) => [...PROJECT_QUERY_KEYS.PROJECT, "SIMPLE", projectId],
    PROJECT_HEADER: (projectId) => [...PROJECT_QUERY_KEYS.PROJECT, "HEADER", projectId]
}

const fetchProject = async (projectId) => {
    const { data } = await clientApi.get(`/projects/${projectId}/simple`)
    return data
}

export const useProject = (projectId) => {
    return useQuery(
        PROJECT_QUERY_KEYS.PROJECT_SIMPLE(projectId),
        () => fetchProject(projectId),
        {
            enabled: !!projectId,
            staleTime: Infinity
        }
    )
}

export const useProjectForDiagnostic = (projectId) => {
    return useQuery(
        PROJECT_QUERY_KEYS.PROJECT_SIMPLE(projectId),
        () => fetchProject(projectId),
        {
            enabled: !!projectId,
            refetchOnWindowFocus: 'always'
        }
    )
}

export const useGetProjectSimple = (projectId) => {
    return useQuery(
        ["ProjectSimple", projectId],
        () => getProjectSimple(projectId),
        {
            enabled: !!projectId,
        }
    );
};

export const useProjectReportsUnits = (projectId) => {
    return useQuery(
        ["projectReportsUnits", projectId],
        () => fetchProjectReportsUnits(projectId),
        {
            enabled: !!projectId
        }
    )
}

export const useGetProjectById = (projectId) => {
    return useQuery(
        ["Project", projectId],
        () => getProjectByProjectId(projectId),
        {
            enabled: !!projectId,
        }
    );
};

export const useGetNewProjectHeaderInformationByProjectId = (projectId) => {
    return useQuery(
        ["Project", projectId],
        () => getNewProjectHeaderInformationByProjectId(projectId),
        {
            enabled: !!projectId,
        }
    );
};

export const useGetProjectClientTeamByProjectId = (projectId, appDataInstanceId) => {
    return useQuery(
        ["ClientTeam", projectId, appDataInstanceId],
        () => getProjectClientTeamByProjectId(projectId, appDataInstanceId),
        {
            enabled: !!projectId && !!appDataInstanceId,
            onError: (err) => {
                return { message: err }
            }
        }
    );
};

export const useGetKeyDeliverablesByProjectId = (projectId) => {
    return useQuery(
        ["SectionKeyDeliverables", projectId],
        () => getKeyDeliverablesByProjectId(projectId),
        {
            enabled: !!projectId,
        }
    );
};

export const useGetProjectReportKeyDeliverables = (projectId) => {
    return useQuery(
        ["ProjectReportKeyDeliverables", projectId],
        () => getProjectReportKeyDeliverables(projectId),
        {
            enabled: !!projectId,
        }
    );
};

export const useInsertProjectReportKeyDeliverables = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (reqBody) => postKeyDeliverablesByProjectId(projectId, reqBody),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectReportKeyDeliverables');
            }
        }
    )
}

export const useUpdateProjectReportKeyDeliverables = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (reqBody) => putKeyDeliverablesByProjectId(projectId, reqBody),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectReportKeyDeliverables');
            }
        }
    )
}

export const useDeleteProjectReportKeyDeliverables = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (reqBody) => deleteKeyDeliverablesByProjectId(projectId, reqBody),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectReportKeyDeliverables');
            }
        }
    )
}

export const useProjectAuthorizationQuery = (projectId, emailAddress) => {
    return (
        ["ProjectAuthorization", projectId, emailAddress],
        () => fetchProjectAuthorization(projectId, emailAddress),
        {
            staleTime: 5000
        }
    )
}

export const useGETProjectsByClientId = (clientId) => {
    const queryClient = useQueryClient()
    return useQuery(
        ["ProjectsByClientId", clientId],
        () => getProjectsByClientId(clientId),
        {
            enabled: !!clientId,
            refetchOnWindowFocus: false,
            onError: () => {
                return queryClient.setQueryData(["ProjectsByClientId", clientId], []);
            }
        }
    )
}

export const useGETProjectClientHeaderInformationByProjectId = (projectId) => {
    return useQuery(
        PROJECT_QUERY_KEYS.PROJECT_HEADER(projectId),
        () => getNewProjectHeaderInformationByProjectId(projectId),
        {
            enabled: !!projectId,
        }
    )
}

export const useInsertProject = () => {
    const queryClient = useQueryClient()

    return useMutation(
        (reqBody) => createProject(reqBody),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('Project');
            }
        }
    )
}

export const useProjectStateUpdate = (onSuccess = null, onError = null) => {
    return useMutation(
        async (projectId) => {
            return clientApi.put(`/projects/${projectId}/finalize`)
        },
        {
            onSuccess: onSuccess,
            onError: onError
        }
    )
}

export const useProjectStateUnfinalize = () => {
    const queryClient = useQueryClient()

    return useMutation(
        async (projectId) => {
            return clientApi.put(`/projects/${projectId}/un-finalize`)
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries("userPreferences")
            }
        }
    )
}

const deleteClientTeamMember = async (appInstanceUserId, appDataInstanceId, projectId) => {
    const { data } = await clientApi.delete(
        `/projectteams/clientteammembers/${appInstanceUserId}/${projectId}`,
        { params: { appDataInstanceId } }
    );
    return data;
}

export const useDeleteClientTeamMember = (appDataInstanceId, projectId) => {
    const queryClient = useQueryClient();

    return useMutation(
        (appInstanceUserId) => deleteClientTeamMember(appInstanceUserId, appDataInstanceId, projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries("ClientTeam");
            }
        }
    );
}
const createClientUser = async (reqBody, projectId) => {
    try {
        const response = await clientApi.post(`/projectteams/clientteammember/${projectId}`, reqBody);
        return { status: response.status, data: response.data }
    } catch (error) {
        const { response } = error;
        return { status: response.status, message: response?.data?.message ?? error };
    }
}
export const useCreateClientUserMutation = (projectId) => {
    const queryClient = useQueryClient();
    
    return useMutation(
        async (reqBody) => createClientUser(reqBody, projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries("ClientTeam");
            }
        }
    )
}

const updateUserProjectAccess = async (body, projectId) =>{
    try{
        const response = await clientApi.put(`/projectteams/${projectId}/access`, body)
        return { status: response.status, data: response.data }
    }
    catch(error) {
        const { response } = error;
        return { status: response.status, message: response?.data?.message ?? error };
    }
}

export const useUpdateUserProjectAccessMutatation = (projectId, onSuccess = null, onError = null) => {
    return useMutation(
        async (body) => updateUserProjectAccess(body, projectId), 
        {
            onSuccess,
            onError
        }
    )
}
const sendWelcomeEmailInvite = async (reqBody) => {
    const response = await clientApi.patch('/projectteams/clientteammember/sendwelcomeemail', reqBody);
    return response;

}
export const useSendWelcomeEmailInviteMutation = () => {
    return useMutation(
        async (reqBody) => sendWelcomeEmailInvite(reqBody)
    )
}

export const useGetProjectTeamMembersByProjectId = (projectId) => {
    return useQuery(
        PROJECT_QUERY_KEYS.PROJECT_TEAM_MEMBER(projectId),
        () => getTeamMembersDataByProjectId(projectId),
        {
            enabled: !!projectId
        }
    )
}