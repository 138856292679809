import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query";
import logger from '@utilities/logService'

const mapResponseToForms = (data) => {
  return data.map((forms) => {
    const {
      FormId,
      Industry,
      FormCommentInfo,
      Category: { Category: CategoryName, FormCategoryId },
      FormName,
      FormDescription,
      FormTypeId,
      Methodology: { MethodologyVersion, MethodologyVersionId, MethodologyVersionStatusId },
      Status: { FormStatusName },
      FormSignOff,
    } = forms;
    return {
      id: FormId,
      industry: Industry,
      category: CategoryName,
      formCategoryId: FormCategoryId,
      formName: FormName,
      formDescription: FormDescription,
      formTypeId: FormTypeId,
      methodology: MethodologyVersion,
      methodologyVersionId: MethodologyVersionId,
      methodologyVersionStatusId: MethodologyVersionStatusId,
      approvalComment: FormCommentInfo,
      status: FormStatusName,
      formSignOff: FormSignOff?.map((user) => ({
        formSignOffLevel: user.FormSignOffLevel,
        formSignOffLevelId: user.FormSignOffLevelId,
        formSignoffId: user.FormSignoffId,
        signOffDate: user.SignOffDate,
        signOffOrder: user.SignOffOrder,
        signOffUser: user.SignOffUser,
      })),
    };
  });
};

const getFormsLibrary = async () => {
  try {
    const response = await formApi.get('/forms');
    const formsList = mapResponseToForms(response.data);
    return { status: 200, data: formsList };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getFormsLibraryQuery = async (
  methodologyVersionId,
  sortby,
  sortorder,
  searchby,
  searchtext,
  operator
) => {
  let params;
  if (operator === 'isEmpty') {
    const searchValue = searchby;
    params = {
      methodologyVersionId,
      sortby,
      sortorder,
      searchbynotlike: 'FormSignOff',
      searchnotliketext: searchValue,
    };
  } else if (searchby === 'preparer' || searchby === 'reviewer') {
    const searchValue = searchby;
    params = {
      methodologyVersionId,
      sortby,
      sortorder,
      searchby: 'FormSignOff',
      searchtext: searchValue === 'reviewer' ? 'reviewer 1' : searchValue,
    };
  } else {
    params = {
      methodologyVersionId,
      sortby,
      sortorder,
      searchby,
      searchtext,
    };
  }

  try {
    const response = await formApi.get('/forms', { params });

    const formsList = mapResponseToForms(response.data);
    return { status: 200, data: formsList };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const convertFormikValues = (form) => {
  const {
    formCategoryId,
    formName,
    formDescription,
    methodologyVersionId,
    status,
    toolTipText,
    formIndustry,
    requiredSignOffLevel
  } = form;

  const newForm = {
    FormCategoryId: formCategoryId,
    FormName: formName,
    FormDescription: formDescription,
    MethodologyVersionId: methodologyVersionId,
    FormStatusId: status,
    ToolTipText: toolTipText,
    FormIndustry: formIndustry.map((i) => ({
      MethodologyIndustryId: i.value,
      IsDefaultForIndustry: i.default,
      IsRequired: i.required,
    })),
    RequiredSignOffLevel: requiredSignOffLevel,
    IsLimited: false,
  };

  return newForm;
};

const saveForm = async (form) => {
  const newForm = convertFormikValues(form);

  try {
    const response = await formApi.post('/forms', newForm);
    return { status: 200, data: response };
  } catch (error) {
    return { status: 400, message: error?.response ?? error };
  }
};

const mapResponseToForm = (data) => {
  const {
    Category,
    FormName,
    FormSchemaId,
    FormDescription,
    Methodology,
    Status,
    ToolTipText,
    Industry,
    FormTypeId,
    RequiredSignOffLevel
  } = data;

  const form = {
    formCategoryId: Category.FormCategoryId,
    formName: FormName,
    formSchemaID: FormSchemaId,
    formDescription: FormDescription,
    methodologyVersionId: Methodology.MethodologyVersionId,
    methodologyVersionStatusId: Methodology.MethodologyVersionStatusId,
    status: Status.FormStatusId,
    formStatusName: Status.FormStatusName,
    addToolTip: !!ToolTipText,
    toolTipText: ToolTipText,
    formIndustry: Industry,
    formTypeId: FormTypeId,
    requiredSignOffLevel: RequiredSignOffLevel
  };
  return form;
};

const getFormById = async (formId) => {
  try {
    const { data } = await formApi.get(`/forms/${formId}/`);

    return mapResponseToForm(data);
  } catch (error) {
    return undefined;
  }
};

const duplicateFormById = async (formId, form) => {
  const newForm = convertFormikValues(form);
  try {
    const response = await formApi.post(`/forms/${formId}/duplicate`, newForm);
    return response;
  } catch (error) {
    return { status: 400, message: error?.response ?? error };
  }
};

const deleteFormById = async (formId) => {
  try {
    const response = await formApi.delete(`/forms/${formId}`);
    return { status: 200, data: response };
  } catch (error) {
    logger.error(error);
    return { status: 400, message: error };
  }
};
const fetchProjectFormList = async (projectId) => {
  try {
    const { data } = await formApi.get(`/forms/form-instance-list/${projectId}`);
    return data;
  } catch (error) {
    logger.error(error);
    return { status: 400, message: error };
  }
};

const getAnswers = async (projectId) => {
  try {
    const { data } = await formApi.get(`/answers/${projectId}`);
    return data;
  } catch (error) {
    logger.error(error);
    return { status: 400, message: error };
  }
};

const updateForm = async (formId, form) => {
  const updatedForm = convertFormikValues(form);
  try {
    await formApi.put(`/forms/${formId}`, updatedForm);
    return { status: 200 };
  } catch (error) {
    return { status: 400, message: error?.response ?? error };
  }
};

const approveForm = async (rowId) => {
  try {
    await formApi.put(`/forms/${rowId}/approve`);
    return { status: 200 };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const unapproveForm = async (rowId) => {
  try {
    await formApi.put(`/forms/${rowId}/unapprove`);
    return { status: 200 };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getFormStatus = async () => {
  const response = await formApi.get('/formStatus');
  const formStatus = response.data.map((status) => {
    const { FormStatusId, FormStatusName } = status;
    return {
      FormStatusId,
      FormStatusName,
    };
  });

  try {
    return { status: 200, statusData: formStatus };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const saveFormSchemaById = async (formId, schema, formRules, formProcedures) => {
  try {
    const body = { Schema: schema, FormRules: formRules ?? [], Procedures: formProcedures ?? [] };
    const { data } = await formApi.post(`/forms/${formId}/schema`, body);
    return { status: 200, data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getFormWithSchemaByFormId = async (formId) => {
  try {
    const { data } = await formApi.get(`/forms/${formId}/true`);
    const form = mapResponseToForm(data);
    const { Schema, FormRules, Procedures } = data;
    return { formSettings: form, formSchema: Schema ?? [], formRules: FormRules ?? [], formProcedures: Procedures ?? [] };
  } catch (error) {
    return undefined;
  }
};

const deleteQuestionById = async (questionId, formSchemaID) => {
  const response = await formApi.delete(
    `/forms/deleteQuestionById/${questionId}/${formSchemaID}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  if (response.status === 200) {
    return { code: response.status };
  } else {
    return { message: response.data.message };
  }
};

const deleteFormrulesByQuestionId = async (formId, questionId) => {
  const response = await formApi.delete(
    `/forms/deleteFormrulesByQuestionId/${formId}/${questionId}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  if (response.status === 200) {
    return { code: response.status };
  } else {
    return { message: response.data.message };
  }
};



const deleteSectionById = async (sectionId, formSchemaID) => {
  const response = await formApi.delete(
    `/forms/deleteSectionById/${sectionId}/${formSchemaID}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );

  if (response.status === 200) {
    return { code: response.status };
  } else {
    return undefined;
  }
};

const deleteFormRulesBySectionId = async (sectionId) => {
  const response = await formApi.delete(
    `/forms/deleteFormRulesBySectionId/${sectionId}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );

  if (response.status === 200) {
    return { code: response.status };
  } else {
    return undefined;
  }
};


const getRelatedFieldsBySectionId = async (sectionId, formSchemaID) => {
  const response = await formApi.get(
    `/forms/getRelatedFieldsBySectionId/${sectionId}/${formSchemaID}`
  );

  if (response.status === 200) {
    return { data: response.data };
  } else {
    return undefined;
  }
};

const getMethodologyVersions = async () => {
  try {
    const response = await formApi.get('/methodologyversion');
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getMethodologyVersionsPro = async () => {
  const { data } = await formApi.get('/methodologyversion');
  return data
};

export const useMethodologyVersions = (onSuccess = undefined) => {
  return useQuery(
    ["MethodologyVersionsPro"],
    () => getMethodologyVersionsPro(),
    {
      staleTime: 60 * 5 * 1000,
      select: (methodologyVersionsData) => {
        return methodologyVersionsData?.map(({ MethodologyVersionStatus, MethodologyVersion, MethodologyVersionLabel, MethodologyVersionID, IsProcedureLibraryApproved }) => {
          const methodologyVersionStatusId = MethodologyVersionStatus[0]?.MethodologyVersionStatusId;
          const methodologyVersionStatus = MethodologyVersionStatus[0]?.MethodologyVersionStatus;
          const methodologyLabel = (methodologyVersionStatusId === 3 || methodologyVersionStatusId === 4) ? MethodologyVersion : MethodologyVersionLabel;
          const methodologyVersionId = MethodologyVersionID;

          return {
            id: `${methodologyVersionId}-${methodologyVersionStatusId}`,
            label: `${methodologyLabel} (${methodologyVersionStatus})`,
            versionId: methodologyVersionId,
            versionName: methodologyLabel,
            statusId: methodologyVersionStatusId,
            IsProcedureLibraryApproved
          }
        })
      },
      onSuccess: onSuccess
    }
  );
};

const getAuditAreas = async () => {
  try {
    const response = await formApi.get('/auditareas');
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getAuditAreasByMethodologyVersionId = async (methodologyVersionId, isEnabledOnly = false) => {
  try {
    const response = await formApi.get(`/auditareas/${methodologyVersionId}/methodology`);
    return { status: 200, data: (!isEnabledOnly) ? response.data : response.data.filter(({ IsEnabled }) => IsEnabled) };
  } catch (exception) {
    return { status: 400, message: exception };
  }
};

export const useAuditAreasByMethodologyVersionId = (methodologyVersionId, filtered = false) => {
  return useQuery(
    ["AuditAreasMethodology", methodologyVersionId],
    async () => {
      const { data } = await getAuditAreasByMethodologyVersionId(methodologyVersionId)
      return data
    },
    {
      enabled: !!methodologyVersionId,
      staleTime: 5 * 60 * 1000,
      select: (auditAreas) => {
        if (!filtered) {
          return auditAreas
        }

        const auditAreasFiltered = auditAreas
          .filter((auditArea) => auditArea.IsEnabled)
          .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
          .map((auditArea) => ({
            label: auditArea.AuditAreaName,
            value: auditArea.AuditAreaId,
            methodologyIndustries: auditArea.MethodologyIndustries?.map(
              (mi) => mi.MethodologyIndustryId
            ),
          }));

        return auditAreasFiltered
      }
    }
  );
};

const getSCOTABDs = async (id, isAuditArea = true, isEnabledOnly = false) => {
  try {
    const response = await formApi.get(`scotabd/${id}/${isAuditArea}`);
    let data;

    if(isEnabledOnly) {
      data = response.data.filter((scotabd) => scotabd.IsEnabled)
    } else {
      data = response.data
    }

    return { status: 200, data: data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

// #region signoff

const signOffForm = async (formId, levelId, userId) => {
  const signOffUser = {
    FormId: formId,
    FormSignOffLevelId: levelId,
    SignOffUser: userId,
    SignOffDate: new Date().toISOString(),
    SignOffOrder: 1,
  };

  try {
    const response = await formApi.post(`/formsignoffs`, signOffUser);
    if (response.status === 201) {
      const { status, data } = response;
      return {
        status,
        data: {
          formSignOffLevelId: data.FormSignOffLevelId,
          formSignoffId: data.FormSignoff,
          signOffDate: data.SignOffDate,
          userName: '',
          signOffOrder: data.SignOffOrder,
          signOffUser: data.SignOffUser,
        },
      };
    }
    return response;
  } catch (error) {
    return { status: 400, message: error };
  }
};

const removeFormSignOff = async (formsignoffId) => {
  try {
    const response = await formApi.delete(`/formsignoffs/${formsignoffId}`);
    return { status: 200, data: response };
  }
  catch (error) {
    return { status: 400, message: error };
  }
}

// #endregion signoff

const getProjectScopeByProjectFormId = async (projectFormId, projectId) => {
  try {
    const url = `/projectscopes/getProjectScopeByProjectFormId/${projectFormId}/${projectId}`;
    const response = await formApi.get(url);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

export const useProjectScopeByProjectForm = (projectFormId, projectId) => {
  return useQuery(
    ["projectScopeForm", projectFormId],
    async () => {
      const { data } = await formApi.get(`/projectscopes/getProjectScopeByProjectFormId/${projectFormId}/${projectId}`)
      return data
    },
    {
      enabled: !!projectFormId
    }
  )
}

const addProjectScopeAuditArea = async (projectId, payload) => {
  try {
    const response = await formApi.post(`/projectscopesauditarea/${projectId}`, payload);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const updateProjectScopeAuditArea = async (projectId, projectScopeAuditAreaId, payload) => {
  try {
    const url = `/projectscopesauditarea/${projectId}/${projectScopeAuditAreaId}`;
    const response = await formApi.put(url, payload);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const deleteProjectScopeAuditArea = async (projectId, projectScopeAuditAreaId, projectFormId, projectScopeId) => {
  try {
    const url = `/projectscopesauditarea/${projectId}/${projectScopeAuditAreaId}/${projectFormId}/${projectScopeId}`;
    const response = await formApi.delete(url);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getSCOTABDsByMethodologyVersionId = async (methodologyVersionId, isEnabledOnly = false) => {
  try {
    const url = `/scotabd/${methodologyVersionId}`;
    const response = await formApi.get(url);
    return { status: 200, data: (!isEnabledOnly) ? response.data : response.data.filter(({ IsEnabled }) => IsEnabled) };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const addProjectScopeAuditAreaSCOTABD = async (projectId, projectScopeAuditAreaId, projectFormId, payload) => {
  try {
    const url = `/projectscopesauditareascotabd/${projectId}/${projectScopeAuditAreaId}/${projectFormId}`;
    const response = await formApi.post(url, payload);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const updateOtherRelevantAssertionAuditAreaSCOTABD = async (projectId, projectScopeAuditAreaSCOTABDId, payload) => {
  try {
    const url = `/projectscopesauditareascotabd/${projectId}/otherrelevantassertion/${projectScopeAuditAreaSCOTABDId}`;
    const response = await formApi.put(url, payload);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }

}

const getFrameworksByMethodologyVersionId = async (methodologyVersionId) => {
  try {
    const url = `/frameworks/${methodologyVersionId}`;
    const response = await formApi.get(url);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getAccountBalancesByMethodologyVersion = async (methodologyVersionId, isEnabledOnly = false) => {
  try {
    const url = `/accountbalances/${methodologyVersionId}`;
    const response = await formApi.get(url);
    return { status: 200, data: (!isEnabledOnly) ? response.data : response.data.filter(({ IsEnabled }) => IsEnabled) };
  } catch (error) {
    return { status: 400, message: error };
  }
}

const getProjectScopeAuditAreaSCOTABDListbyAuditAreaIds = async (projectScopeAuditAreaIds, projectId) => {
  try {
    const { data } = await formApi.get(`/projectscopesauditareascotabdlist/${projectId}?ProjectScopeAuditAreaIds=${projectScopeAuditAreaIds}`);
    return data?.map(scotabd => ({
      projectScopeAuditAreaId: scotabd.ProjectScopeAuditAreaId,
      projectScopeAuditAreaSCOTABDId: scotabd.ProjectScopeAuditAreaSCOTABDId,
      classOfTransactionName: scotabd.ClassOfTransactionName,
      accountBalanceType: scotabd.AccountBalanceType?.map(account => ({
        accountBalanceTypeId: account.AccountBalanceTypeId,
        accountBalanceName: account.AccountBalanceName
      })),
      assertion: scotabd.Assertion?.map(assertion => ({
        assertionId: assertion.AssertionId,
        assertionName: assertion.AssertionName
      })),
      validFrom: scotabd.ValidFrom
    }));
  }
  catch (error) {
    logger.error(error);
  }
};


const getProjectScopeAuditAreaSCOTABDRiskList = async (auditAreaSCOTABDId, projectId) => {
  try {
    const url = Array.isArray(auditAreaSCOTABDId) ?
      `/projectscopesauditareascotabdrisklist/${projectId}?idList=${JSON.stringify(auditAreaSCOTABDId)}`
      : `/projectscopesauditareascotabdrisklist/${auditAreaSCOTABDId}/${projectId}`;
    const { data } = await formApi.get(url);
    return {
      riskInfo: data.RiskInfo?.map(risk => ({
        projectRiskId: risk.ProjectRiskId,
        projectScopeAuditAreaSCOTABDId: risk.ProjectScopeAuditAreaSCOTABDId,
        riskName: risk.RiskName,
        projectRiskAuditAreaId: risk.ProjectRiskAuditAreaId,
        referenceForWalkThrough: risk.ReferenceForWalkThrough,
        inherentRiskAssessmentId: risk.InherentRiskAssessmentId,
        inherentRiskAssessmentName: risk.InherentRiskAssessmentName,
        referenceForTestOfControl: risk.ReferenceForTestOfControl,
        controlRiskAssessmentId: risk.ControlRiskAssessmentId,
        controlRiskAssessmentName: risk.ControlRiskAssessmentName,
        riskOfMaterialMisstatementName: risk.RiskOfMaterialMisstatementName,
        isPervasive: risk.IsPervasive,
        workpaperReference: risk.WorkpaperReference,
        projectFormName: risk.ProjectFormName,
        assertion: risk.Assertion?.map(assertion => (
          {
            assertionId: assertion.AssertionId,
            assertionName: assertion.AssertionName
          })),
        procedures: risk.ProjectRiskSummaryProcedures?.map(procedure => (
          {
            projectRiskSummaryProcedureId: procedure.ProjectRiskSummaryProcedureId,
            summaryProcedureId: procedure.SummaryProcedureId,
            summaryProcedureName: procedure.SummaryProcedureName
          })),
        customProcedures: risk.ProjectFormCustomProcedures?.map(procedure => (
          {
            projectFormCustomProcedureId: procedure.ProjectFormCustomProcedureId,
            projectRiskProjectFormCustomProcedureId: procedure.ProjectRiskProjectFormCustomProcedureId,
            customProcedureName: procedure.CustomProcedureName
          }
        )),
        financialStatementProjectRisk: risk.FinancialStatementProjectRisk?.map(financial => (
          {
            financialStatementProjectRiskId: financial.FinancialStatementProjectRiskId,
            riskName: financial.RiskName
          })),
        units: risk.Units?.map(unit => (
          {
            projectUnitId: unit.ProjectUnitId,
            projectUnitTitle: unit.ProjectUnitTitle
          })),
        projectRiskSummaryProcedures: risk.ProjectRiskSummaryProcedures,
        validFrom: risk.ValidFrom,
        defaultRiskId: risk.DefaultRiskId,
        riskCharacteristics: risk.RiskCharacteristics ? JSON.parse(risk.RiskCharacteristics).map(riskCharacteristic => (
          {
            riskCharacteristicName: riskCharacteristic.RiskCharacteristicName
          })) : [],
      })),
      riskFactorInfo: data.RiskFactorInfo?.map(risk => (
        {
          riskFactorId: risk.RiskFactorId,
          riskFactorName: risk.RiskFactorName,
          eventCondition: risk.EventCondition?.map(event => (
            {
              eventConditionName: event.EventConditionName
            }))
        }))
    };
  }
  catch (error) {
    logger.error(error);
  }
};

const getProjectScopeAuditAreaSCOTABDOtherRelevantAssertions = async (auditAreaSCOTABDId, projectId) => {
  try {
    const { data } = await formApi.get(`/projectscopesauditareascotabd/otherrelevantassertion/${auditAreaSCOTABDId}/${projectId}`);
    const { OtherRelevantAssertion, RiskFactorInfo } = data;
    return {
      otherRelevantAssertion: {
        projectScopeAuditAreaSCOTABDId: OtherRelevantAssertion.ProjectScopeAuditAreaSCOTABDId,
        referenceForWalkThrough: OtherRelevantAssertion.ReferenceForWalkThrough,
        inherentRiskAssessmentId: OtherRelevantAssertion.InherentRiskAssessmentId,
        inherentRiskAssessmentName: OtherRelevantAssertion.InherentRiskAssessmentName,
        referenceForTestOfControl: OtherRelevantAssertion.ReferenceForTestOfControl,
        controlRiskAssessmentId: OtherRelevantAssertion.ControlRiskAssessmentId,
        controlRiskAssessmentName: OtherRelevantAssertion.ControlRiskAssessmentName,
        riskOfMaterialMisstatementName: OtherRelevantAssertion.RiskOfMaterialMisstatementName,
        assertionList: OtherRelevantAssertion.AssertionList?.map(assertion => (
          {
            assertionId: assertion.AssertionId,
            assertionName: assertion.AssertionName
          }))
      },
      riskFactorInfo: RiskFactorInfo?.map(risk => (
        {
          riskFactorId: risk.RiskFactorId,
          riskFactorName: risk.RiskFactorName,
          eventCondition: risk.EventCondition?.map(event => (
            {
              eventConditionName: event.EventConditionName
            }))
        }))
    };
  }
  catch (error) {
    logger.error(error);
  }
};

const getBatchProjectScopeAuditAreaSCOTABDOtherRelevantAssertions = async (auditAreaSCOTABDId, projectId) => {
  try {
    const url = Array.isArray(auditAreaSCOTABDId) ?
      `/projectscopesauditareascotabd/otherrelevantassertion/${projectId}?idList=${JSON.stringify(auditAreaSCOTABDId)}`
      : `/projectscopesauditareascotabd/otherrelevantassertion/${auditAreaSCOTABDId}/${projectId}`;

    const { data } = await formApi.get(url);
    const { OtherRelevantAssertion } = data;
    const otherRelevanAssertionMap = {
      otherRelevantAssertions: OtherRelevantAssertion.map(otherRelevant => ({
        projectScopeAuditAreaSCOTABDId: otherRelevant.ProjectScopeAuditAreaSCOTABDId,
        referenceForWalkThrough: otherRelevant.ReferenceForWalkThrough,
        inherentRiskAssessmentId: otherRelevant.InherentRiskAssessmentId,
        inherentRiskAssessmentName: otherRelevant.InherentRiskAssessmentName,
        referenceForTestOfControl: otherRelevant.ReferenceForTestOfControl,
        controlRiskAssessmentId: otherRelevant.ControlRiskAssessmentId,
        controlRiskAssessmentName: otherRelevant.ControlRiskAssessmentName,
        riskOfMaterialMisstatementName: otherRelevant.RiskOfMaterialMisstatementName,
        assertionList: otherRelevant.AssertionList.map(assertion => (
          {
            assertionId: assertion.AssertionId,
            assertionName: assertion.AssertionName
          })),
        validFrom: otherRelevant.ValidFrom,
      }))
    };
    return otherRelevanAssertionMap;
  }
  catch (error) {
    logger.error(error);
  }
};

export const useGetSCOTABDsByMethodologyVersionId = (
  methodologyVersionId
) => {
  return useQuery(
    ["GetScotabdByMethodologyVersionId", methodologyVersionId],
    () => getSCOTABDsByMethodologyVersionId(methodologyVersionId),
    {
      enabled: !!methodologyVersionId
    }
  );
};

export const useGetFrameworksByMethodologyVersionId = (methodologyVersionId, filtered = false) => {
  return useQuery(
    ["GetFrameworkByMethodologyVersionId", methodologyVersionId],
    () => getFrameworksByMethodologyVersionId(methodologyVersionId),
    {
      enabled: !!methodologyVersionId,
      select: (frameworks) => {
        if (!filtered) {
          return frameworks
        }

        const frameworksFiltered = frameworks.data
          .filter((frs) => frs.IsEnabled)
          .map((frs) => ({
            label: frs.FrameworkName,
            value: frs.FrameworkId,
          }));

        return frameworksFiltered
      }
    }
  );
};

const getProceduresLibraryApprovalComments = async (methodologyVersionId) => {
  try {
    const { data } = await formApi.get(`/all/procedurelibrarycommentandreply/${methodologyVersionId}`)
    return { status: 200, data };
  } catch (error) {
    logger.error(error);
  }
}

const getRuleCriteriaType = async () => {
  try {
    const { data } = await formApi.get('/forms/rule-criteria')
    return { status: 200, data };
  } catch (error) {
    logger.error(error);
  }
}


export const useGetRuleCriteriaType = () => {
  return useQuery(
    ["GetRuleCriteriaType"],
    () => getRuleCriteriaType()
  );
};


const formServices = {
  approveForm,
  getFormsLibrary,
  saveForm,
  getFormById,
  duplicateFormById,
  deleteFormById,
  updateForm,
  getFormWithSchemaByFormId,
  saveFormSchemaById,
  getFormsLibraryQuery,
  getFormStatus,
  deleteQuestionById,
  deleteFormrulesByQuestionId,
  deleteFormRulesBySectionId,
  deleteSectionById,
  getRelatedFieldsBySectionId,
  signOffForm,
  removeFormSignOff,
  getMethodologyVersions,
  getAuditAreas,
  getSCOTABDs,
  unapproveForm,
  getAuditAreasByMethodologyVersionId,
  getProjectScopeByProjectFormId,
  addProjectScopeAuditArea,
  updateProjectScopeAuditArea,
  deleteProjectScopeAuditArea,
  getSCOTABDsByMethodologyVersionId,
  addProjectScopeAuditAreaSCOTABD,
  updateOtherRelevantAssertionAuditAreaSCOTABD,
  getFrameworksByMethodologyVersionId,
  getAccountBalancesByMethodologyVersion,
  getProjectScopeAuditAreaSCOTABDListbyAuditAreaIds,
  getProjectScopeAuditAreaSCOTABDRiskList,
  getProjectScopeAuditAreaSCOTABDOtherRelevantAssertions,
  getBatchProjectScopeAuditAreaSCOTABDOtherRelevantAssertions,
  getProceduresLibraryApprovalComments,
  useGetSCOTABDsByMethodologyVersionId,
  fetchProjectFormList,
  getAnswers
};

export default formServices;