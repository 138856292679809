import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'

function realignCustomProcedures(concurrencyCustomProcedures, oldCustomProcedures) {
    const mapCustomProcedure = map(({ ProjectFormCustomProcedureId }) => ProjectFormCustomProcedureId);
    let updatedOldCustomProcedures = [...oldCustomProcedures].filter(el => el !== undefined)

    const incomingCustomProcedureIds = mapCustomProcedure(concurrencyCustomProcedures);
    const oldCustomProcedureIds = mapCustomProcedure(updatedOldCustomProcedures);
    const newCustomProcedureIds = incomingCustomProcedureIds.filter(id => !oldCustomProcedureIds.includes(id));
    const deletedCustomProcedureIds = oldCustomProcedureIds.filter(id => !incomingCustomProcedureIds.includes(id));


    // Delete
    if (updatedOldCustomProcedures.length > 0) {
        updatedOldCustomProcedures = updatedOldCustomProcedures
            .filter(({ ProjectFormCustomProcedureId }) => !deletedCustomProcedureIds.includes(ProjectFormCustomProcedureId));
    }

    // Insert
    newCustomProcedureIds.forEach(id => {
        const newCustomProcedure = concurrencyCustomProcedures.find(({ ProjectFormCustomProcedureId }) => ProjectFormCustomProcedureId === id);
        updatedOldCustomProcedures.push(newCustomProcedure);
    });
    return updatedOldCustomProcedures;
}

function realignSummaryProcedureStep(concurrencySteps, newStateSteps) {
    const checkedOrRequiredSteps = flow(
        filter(({IsChecked, IsRequiredStep}) => IsChecked || IsRequiredStep),
        map(({SummaryProcedureStepId}) => SummaryProcedureStepId)
    )
    let updatedNewStateSteps = [...newStateSteps].filter(el => el !== undefined)

    const incomingStepsIds = checkedOrRequiredSteps(concurrencySteps);
    const oldStepsIds = checkedOrRequiredSteps(updatedNewStateSteps);
    const newStepIds = incomingStepsIds.filter(id => !oldStepsIds.includes(id));
    const deletedStepIds = oldStepsIds.filter(id => !incomingStepsIds.includes(id));

    // Delete
    if (updatedNewStateSteps.length > 0) {
        updatedNewStateSteps = updatedNewStateSteps
            .filter(({ SummaryProcedureStepId }) => !deletedStepIds.includes(SummaryProcedureStepId));
    }

    // Insert
    newStepIds.forEach(id => {
        const newStep = concurrencySteps.find(({ SummaryProcedureStepId }) => SummaryProcedureStepId === id);
        updatedNewStateSteps.push(newStep);
    });
    return updatedNewStateSteps
}

function realignSummaryProcedures(concurrencySummaryProcedures, oldSummaryProcedures) {
    const mapSummaryProcedures = map(({ SummaryProcedureId }) => SummaryProcedureId);
    let updatedOldSummaryProcedures = [...oldSummaryProcedures].filter(el => el !== undefined)

    const incomingSummaryProcedureIds = mapSummaryProcedures(concurrencySummaryProcedures);
    const oldSummaryProcedureIds = mapSummaryProcedures(updatedOldSummaryProcedures);
    const newSummaryProcedureIds = incomingSummaryProcedureIds.filter(id => !oldSummaryProcedureIds.includes(id));
    const deletedSummaryProcedureIds = oldSummaryProcedureIds.filter(id => !incomingSummaryProcedureIds.includes(id));
    const updatedSummaryProcedureIds = oldSummaryProcedureIds.filter(id => !deletedSummaryProcedureIds.includes(id));

    // Delete
    if (updatedOldSummaryProcedures.length > 0) {
        updatedOldSummaryProcedures = updatedOldSummaryProcedures
            .filter(({ ProjectFormCustomProcedureId }) => !deletedSummaryProcedureIds.includes(ProjectFormCustomProcedureId));
    }

    // Insert
    newSummaryProcedureIds.forEach(id => {
        const newCustomProcedure = concurrencySummaryProcedures.find(({ ProjectFormCustomProcedureId }) => ProjectFormCustomProcedureId === id);
        updatedOldSummaryProcedures.push(newCustomProcedure);
    });
    updatedOldSummaryProcedures = updatedOldSummaryProcedures.filter(el => el !== undefined)

    updatedSummaryProcedureIds.forEach(id => {
        const newStateSummaryProcedureSteps = updatedOldSummaryProcedures.find(({SummaryProcedureId}) => SummaryProcedureId === id).SummaryProcedureSteps;
        const concurrencySummaryProcedureSteps = concurrencySummaryProcedures.find(({SummaryProcedureId}) => SummaryProcedureId === id).SummaryProcedureSteps;
        updatedOldSummaryProcedures.find(({SummaryProcedureId}) => SummaryProcedureId === id).SummaryProcedureSteps = realignSummaryProcedureStep(concurrencySummaryProcedureSteps, newStateSummaryProcedureSteps)
        updatedOldSummaryProcedures.find(({SummaryProcedureId}) => SummaryProcedureId === id).ProjectRiskSummaryProcedure = concurrencySummaryProcedures.find(({SummaryProcedureId}) => SummaryProcedureId === id).ProjectRiskSummaryProcedure;
    });
    return updatedOldSummaryProcedures
}

export const TailorProcedureReducer = (state, action) => {
    let newState = { ...state };
    const { type, payload } = action;
    switch (type) {
        case TAILOR_PROCEDURE_REDUCER.LOAD_INIT_TAILOR_PROCEDURE: {
            const {
                sectionId,
                summaryProcedures,
                customProcedures,
                assertions
            } = payload;

            newState[`${sectionId}`] = {
                summaryProcedures: summaryProcedures,
                customProcedures: customProcedures,
                assertions: assertions
            };

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.SET_SUMMARY_PROCEDURE: {
            const {
                summaryProcedures,
                sectionId
            } = payload;

            newState[`${sectionId}`].summaryProcedures = summaryProcedures;

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPDATE_SUMMARY_PROCEDURE: {
            const {
                summaryProcedureStep,
                sectionId
            } = payload
            
            const indexSummaryProcedure = newState[`${sectionId}`].summaryProcedures.findIndex(item => item.SummaryProcedureId === summaryProcedureStep.SummaryProcedureId);
            const indexSummaryProcedureStep = newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure]?.SummaryProcedureSteps.findIndex(item => item.SummaryProcedureStepId === summaryProcedureStep.SummaryProcedureStepId);

            if (indexSummaryProcedure < 0 || indexSummaryProcedureStep < 0) return newState;

            newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure].SummaryProcedureSteps[indexSummaryProcedureStep] = summaryProcedureStep;
            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.SET_CUSTOM_PROCEDURES: {
            newState.customProcedures = action.payload;
            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPSERT_CUSTOM_PROCEDURE: {
            const {
                customProcedure,
                sectionId
            } = payload

            if (newState[`${sectionId}`].customProcedures.length < 0) {
                newState[`${sectionId}`].customProcedures.push(customProcedure);
            } else {
                const index = newState[`${sectionId}`].customProcedures.findIndex(cp => cp.ProjectFormCustomProcedureId === customProcedure.ProjectFormCustomProcedureId);
                index >= 0 ? newState[`${sectionId}`].customProcedures[index] = customProcedure : newState[`${sectionId}`].customProcedures.push(customProcedure);
            }
            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.DELETE_CUSTOM_PROCEDURE: {
            const { id, sectionId } = payload;
            if (newState[`${sectionId}`].customProcedures.length > 0) {
                newState[`${sectionId}`].customProcedures = newState[`${sectionId}`].customProcedures.filter(cp => cp.ProjectFormCustomProcedureId !== id);
            }
            return newState
        }
        case TAILOR_PROCEDURE_REDUCER.REALIGN_PROCEDURE_FROM_CONCURRENCY: {
            const { summaryProcedures, customProcedures, sectionId } = payload;
            const updatedOldCustomProcedures = realignCustomProcedures(customProcedures, newState[sectionId].customProcedures);
            const updatedOldSummaryProcedures = realignSummaryProcedures(summaryProcedures, newState[sectionId].summaryProcedures, sectionId);

            newState[`${sectionId}`].customProcedures = updatedOldCustomProcedures;
            newState[`${sectionId}`].summaryProcedures = updatedOldSummaryProcedures;
            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPDATE_STEP_PROCEDURE_CONTENT: {
            const {
                summaryProcedureStep,
                sectionId
            } = payload
            
            const indexSummaryProcedure = newState[`${sectionId}`].summaryProcedures.findIndex(item => item.SummaryProcedureId === summaryProcedureStep.SummaryProcedureId);
            const indexSummaryProcedureStep = newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure]?.SummaryProcedureSteps.findIndex(item => item.SummaryProcedureStepId === summaryProcedureStep.SummaryProcedureStepId);

            if (indexSummaryProcedure < 0 || indexSummaryProcedureStep < 0) return newState;

            const oldProcedureStep = newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure].SummaryProcedureSteps[indexSummaryProcedureStep];
            newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure].SummaryProcedureSteps[indexSummaryProcedureStep] = {
                ...oldProcedureStep,
                ...summaryProcedureStep
            };

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPDATE_CUSTOM_PROCEDURE_CONTENT: {
            const {
                customProcedure,
                sectionId
            } = payload

            const index = newState[`${sectionId}`].customProcedures.findIndex(cp => cp.ProjectFormCustomProcedureId === customProcedure.ProjectFormCustomProcedureId);

            if (index < 0) return newState;

            const newCustomProcedure = {
                ...newState[`${sectionId}`].customProcedures[index],
                ...customProcedure
            }

            newState[`${sectionId}`].customProcedures[index] = newCustomProcedure;

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPDATE_PROJECT_RISK_SUMMARY_PROCEDURE: {
            const {
                identifiedRisk,
                summaryProcedureId,
                sectionId,
            } = payload

            const indexSummaryProcedure = newState[`${sectionId}`].summaryProcedures.findIndex(item => item.SummaryProcedureId === summaryProcedureId);

            if (indexSummaryProcedure < 0) return newState;

            // state only needs the ProjectRiskId and ProjectRiskSummaryProcedureId
            // state initially has ProjectRiskName but that is not needed
            newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure].ProjectRiskSummaryProcedure = identifiedRisk?.map(({
                ProjectRiskId,
                ProjectRiskSummaryProcedureId
            }) => ({
                ProjectRiskId,
                ProjectRiskSummaryProcedureId
            })) ?? []; 

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPDATE_PROJECT_RISK_CUSTOM_PROCEDURE: {
            const {
                customProcedureRiskList,
                projectFormCustomProcedureId,
                sectionId,
            } = payload

            const index = newState[`${sectionId}`].customProcedures.findIndex(cp => cp.ProjectFormCustomProcedureId === projectFormCustomProcedureId);

            if (index < 0) return newState;

            // state only needs the ProjectRiskId and ProjectRiskSummaryProcedureId
            // state initially has ProjectRiskName but that is not needed
            newState[`${sectionId}`].customProcedures[index].ProjectRiskProjectFormCustomProcedures = customProcedureRiskList?.map(({
                ProjectRiskId,
                ProjectRiskProjectFormCustomProcedureId
            }) => ({
                ProjectRiskId,
                ProjectRiskProjectFormCustomProcedureId
            })) ?? []; 

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.RESET_STATE: {
            return structuredClone(state);
        }
        default: {
            return newState;
        }
    }
};