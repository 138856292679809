import { CLANestedDataGrid } from '@ais/components';
import { useParams } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { useFormContext } from "react-hook-form"
import styles from './CPPPTable.module.css';
import React, { useState, useEffect, useContext } from "react";
import { Comment, CommentResolved } from '@ais/assets'; 
import { Badge, IconButton } from '@mui/material/';

import GridStyle from "./styles.js"
import CPPPCategoryLevel from '../CPPPTable/CPPPCategoryLevel/CPPPCategoryLevel';
import { SchemaContext } from '@ais/providers';
import { useCurrentPeriodComparison } from '@services/trialbalance';
import { getTrialBalanceValues } from '@views/PlanningAnalytics/components/helpers.js';
import { formatMoneyDecimalAndComma, formatDecimalToPercent } from "@utilities/numberHelper";
import { useDisabledGlobalQueryLoading, useUserList } from "@hooks/index";
import { usePlanningAnalytics } from '../../hooks/usePlanningAnalytics.js';

const CPPPTable = ({ sectionId, isLoadCurrentPeriodSection, section }) => {
  const [rows, setRows] = useState([])
  const [userId, setUserid] = useState([]);
  const [expandedRow, setExpandedRow] = useState([])
  const [trialBalances, setTrialBalances] = useState([]);
  const { schema } = useContext(SchemaContext);
  const { projectId, projectFormId } = useParams();
  const { accounts } = useMsal();
  const {getUserById} = useUserList();
  const [userLocalId, setUserLocalId] = useState('');
  const [ isLoading, setIsLoading ] = useState(true)

  const formCanvas = useFormContext();

  const {
    concurrencyEventReceived,
    comment: concurrencyComment
  } = usePlanningAnalytics()

  useDisabledGlobalQueryLoading()

  useEffect(() => {
    return () => {
      setRows([])
      setExpandedRow([])
    }
  }, [])
  useEffect(() => {
    const getTrialBalance = getTrialBalanceValues(schema, formCanvas.getValues());

    if (!getTrialBalance)
      return;

    const _trialBalanceIds = getTrialBalance.map(data => data.correlationDetailId);
    setTrialBalances(_trialBalanceIds);
  }, [formCanvas])

  const {
    data: currentPeriodComparison,
    isLoading: isLoadingCurrentPeriodComparison,
    isRefetching,
    isSuccess,
    status
  } = useCurrentPeriodComparison(projectFormId, trialBalances, projectId);

  useEffect(() => {
    setIsLoading(true)
  }, [ JSON.stringify(trialBalances) ])

  useEffect(() => {
    if (status === 'error' && !isSuccess) {
      setIsLoading(false);
      return;  
    }
  }, [ isSuccess, status ]) 

  useEffect(() => {
    if (!isLoadCurrentPeriodSection && !concurrencyEventReceived) return
    if (currentPeriodComparison && currentPeriodComparison?.status === 200) {
      if (currentPeriodComparison.data.length < 1) {
        setRows([])
        setIsLoading(false)
        return
      }
      const _dataCopy = [ ...currentPeriodComparison.data ]
      
      _dataCopy.forEach((item, index) => {
        const { CategoryLevelData } = item
        item.id = index
        item.ExpandedPanel =
          <CPPPCategoryLevel
            rowData={CategoryLevelData}
            userId={userId}
            sectionId={sectionId}
            isCommentSectionExpanded={false}
            commentId={item.ProjectFormCommentId}
            comment={item.Comment}
            section={section}
            commentPath={{
              trialBalances: [{
                trialBalanceId: item.CorrelationDetailId,
                trialBalanceName: item.CorrelationName,
              }],
              TypeName: item.TypeName,
              ProjectFormId: projectFormId
            }}
          />
        _dataCopy[index] = item
      })

      setRows(_dataCopy);
      setIsLoading(false)
    }

    return () => {
      setRows([])
    }
  }, [currentPeriodComparison?.data, isLoadingCurrentPeriodComparison, isRefetching, isLoadCurrentPeriodSection, concurrencyEventReceived])

  useEffect(() => { 
    if (currentPeriodComparison && currentPeriodComparison?.status === 200 && concurrencyEventReceived) {
      if (currentPeriodComparison.data.length < 1) {
        setRows([])
        setIsLoading(false)
        return
      }
      const _dataCopy = [...currentPeriodComparison.data]

      _dataCopy.forEach((item, index) => {
        const { CategoryLevelData } = item;
        let comment = item;
        const commentPath = {
          trialBalances: [{
            trialBalanceId: comment.CorrelationDetailId,
            trialBalanceName: comment.CorrelationName,
          }],
          TypeName: comment.TypeName,
          ProjectFormId: projectFormId
        }
        
        if (item.ProjectFormCommentId === concurrencyComment.ProjectFormCommentId || concurrencyComment.CommentPath === JSON.stringify(commentPath))
          comment = {
            ...comment,
            ProjectFormCommentId: concurrencyComment.ProjectFormCommentId,
            Comment: concurrencyComment.Comment
          }
        comment.id = index
        comment.ExpandedPanel =
          <CPPPCategoryLevel
            rowData={CategoryLevelData}
            userId={userId}
            sectionId={sectionId}
            isCommentSectionExpanded={true}
            commentId={comment.ProjectFormCommentId}
            comment={comment.Comment}
            commentPath={commentPath}
            section={section}
          />
        _dataCopy[index] = comment
      })

      setRows(_dataCopy);
      setIsLoading(false)
    }
  }, [concurrencyEventReceived, concurrencyComment])

  useEffect(() => {
    async function initData() {
      const user = await getUserById(accounts[0].localAccountId);
      const userLocalId = user?.activeDirectoryId ?? '';

      setUserLocalId(userLocalId);
    }

    initData()
  }, [])

  const handleAdditionalCollapseTrigger = (newIds) => {
    const rowId = newIds.slice(-1);
    setExpandedRow(rowId)
  }

  const handleCommentToggle = rowId => {
    const index = rows.findIndex(item => item.id === rowId);
    const row = rows[index];

    row.isCommentSectionExpanded = !row.isCommentSectionExpanded;
    row.ExpandedPanel =
      <CPPPCategoryLevel
        rowData={row.CategoryLevelData}
        isCommentSectionExpanded={row.isCommentSectionExpanded}
        userId={userId}
        sectionId={sectionId}
        commentId={row?.ProjectFormCommentId}
        comment={row.Comment}
        section={section}
        commentPath={{
          trialBalances: [{
            trialBalanceId: row.CorrelationDetailId,
            trialBalanceName: row.CorrelationName,
          }],
          TypeName: row.TypeName,
          ProjectFormId: projectFormId
        }}
      />
    setRows((prevState) => {
      const _rowsCopy = [...prevState];
      _rowsCopy.forEach(item => {
        if (item.id !== rowId) {
          item.isCommentSectionExpanded = false
        }
      })
      _rowsCopy[index] = row;

      return _rowsCopy;
    })
    expandedRow.push(rowId)
    handleAdditionalCollapseTrigger(expandedRow)
  }

  const columns = [
    {
      field: 'TypeName',
      headerName: 'Type',
      flex: 1,
      minWidth: 280,
      maxWidth: 280,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
    },
    {
      field: 'CurrentPeriodBalance',
      flex: 1,
      headerName: 'Current Period Unadjusted',
      headerClassName: styles["cppp-header-select"],
      editable: false,
      sortable: false,
      maxWidth: 200,
      minWidth: 200,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      renderHeader: _ => {
        return (
          <>
            <div className={`${styles["cppp-header-container"]} ${styles["cppp-header-container-difference"]}`}>
              <div>
                <strong>Current Period</strong>
              </div>
              <strong>Unadjusted</strong>
            </div>
          </>
        )
      },
      renderCell: params => {
        if(params?.row?.CurrentPeriodBalance == null) return ''
        return formatMoneyDecimalAndComma(params?.row?.CurrentPeriodBalance)
      }
    },
    {
      field: 'PriorPeriodBalance',
      flex: 1,
      headerName: 'Prior Period Final',
      disableColumnMenu: true,
      hideSortIcons: true,
      maxWidth: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      filterable: false,

      headerClassName: styles["cppp-header-select"],
      disableReorder: true,
      renderCell: params => {
        if(params?.row?.PriorPeriodBalance == null) return ''
        return formatMoneyDecimalAndComma(params?.row?.PriorPeriodBalance)
      }
    },
    {
      field: 'DifferenceAmount',
      flex: 1,
      editable: false,
      maxWidth: 200,
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: () => {
        return (
          <div className={`${styles["cppp-header-container"]} ${styles["cppp-header-container-difference"]}`}>
            <div>
              <strong>Difference</strong>
            </div>
            <strong>($)</strong>
          </div>
        )
      },
      renderCell: params => {
        if(params?.row?.DifferenceAmount == null) return ''
        return formatMoneyDecimalAndComma(params?.row?.DifferenceAmount)
      }
    },
    {
      field: 'DifferencePercent',
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: () => {
        return (
          <div className={`${styles["cppp-header-container"]} ${styles["cppp-header-container-difference"]}`}>
            <div>
              <strong>Difference</strong>
            </div>
            <strong>(%)</strong>
          </div>
        )
      },
      renderCell: params => {
        if(params?.row?.DifferencePercent == null) return ''
        return formatDecimalToPercent(params?.row?.DifferencePercent)
      }
    },
    {
      field: 'Comment',
      flex: 1,
      headerName: 'Comments',
      editable: false,
      sortable: false,
      minWidth: 150,
      maxWidth: 150,
      filterable: false,
      disableReorder: true,
      renderCell: (params) => {
        const row = params.row;
        const _hasComment = row.Comment;

        return (
          <Badge
            variant="comment"
          >
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => handleCommentToggle(params?.row?.id)}
            >
              {(_hasComment) ? <CommentResolved /> : <Comment />}
            </IconButton>
          </Badge>
        )
      }
    },
  ];

  useEffect(async () => {
    const userId = userLocalId;
    setUserid(userId);
  }, [userLocalId])

  return (
    <CLANestedDataGrid
      sx={{...GridStyle}}
      rows={rows}
      columns={columns}
      rowHeight={50}
      handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
      expandedRow={expandedRow}
      hideFooterRowCount={true}
      showFooterContent={false}
      hideFooterPagination={true}
      headerHeight={80}
      hideFooter={true}
      loading={isLoading}
    /> 
  )
}

export default CPPPTable
