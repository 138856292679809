import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { useMsal } from '@azure/msal-react';
import { stringHelper as string } from '@ais/utilities';
import { useNotificationsStore } from './store/notifications';
import { markNotificationAsNotified } from '@services/projectPdf';

const baseUrl = process.env.REACT_APP_API_FORM_WEBSOCKET;
const proxy = string.stripPath(baseUrl);
const namespace = new URL('/notifications', baseUrl)
export const socket = io(namespace.href, {
  path: proxy + '/ais',
  transports: ['websocket'],
  autoConnect: false,
  upgrade: false,
});

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const { accounts } = useMsal();
  const userId = accounts[0].localAccountId.toUpperCase();

  const [notificationsRefreshed, setNotificationsRefreshed] = useState(false);

  const notifications = useNotificationsStore((state) => state.notifications);
  const updateNotifications = useNotificationsStore((state) => state.updateNotifications);
 
  const handleNewNotification = async (newNotification) => {
    if (notifications !== null) {
      // Check if the new notification is already present in the notifications array..
      const isNotificationPresent = notifications.some(
        (notification) =>
          notification.JobInstanceId === newNotification.JobInstanceId
      );
      
      if (!isNotificationPresent) {
        // Add the new notification to the notifications array
        updateNotifications([
          ...notifications,
          { ...newNotification, isRead: false, isDelete: false },
        ]);
        // Update Status for Red dot on notification Bell
        if (!notificationsRefreshed) setNotificationsRefreshed(true);
      }
    }
  };
   
  const updateNotification = (index, key, value) => {
    const newNotifications = [...notifications];
    newNotifications[index][key] = value;

    // On notification read or delete, mark the notification as notified
    if ((key === 'isRead' || key === 'isDelete') && value === true) {
      markNotificationAsNotified(newNotifications[index].JobInstanceId);
    }
    setNotificationsRefreshed(true);
    // Remove any deleted notifications from the array
    updateNotifications(newNotifications);
  };

  const onConnect = () =>  {
    socket.emit('subscribe_to_notification', { userId });

  }

  const onDisconnect = () => {
    // Log added for local development
    //logger.info('socket notifications disconnected');
  }

  useEffect(() => {
    socket.connect();

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('notify_subscribers', handleNewNotification);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('notify_subscribers', handleNewNotification);
    };
  }, []);

  return (
    <NotificationContext.Provider
      value={{ socket, notifications, notificationsRefreshed, updateNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within NotificationProvider');
  }
  return context;
};