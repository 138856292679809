import { DELETE_QUESTION_ERROR } from '@constants/forms';

import {DRAG_TYPES, FIELD_TYPES, RULES, SCHEMA_ACTION_TYPES} from '@ais/constants';
import {copyArray, copyObject} from '@ais/forms';
import {DROPZONE_AREAS, TRIGGERS} from '@constants/forms';

import { FORM_SETTINGS, PLANNING_ANALYTICS_FORM_TYPE_ID } from '@ais/constants';
import { FORMSLIBRARY } from '@constants/index';
import {
  CheckboxDialog,
  RadioButtonDialog,
  ShortAnswerDialog,
  LongAnswerDialog,
  FreeTextDialog,
  DropDownDialog,
  DatePickerDialog,
  TableDialog,
  ExistingQuestionDialog,
  QuestionGroupDialog,
} from './CLAFormDesigner/dialogs';
import { FormulaDialog } from './CLAFormDesigner/dialogs/FormulaDialog';
import { v4 as uuidv4 } from 'uuid';

import { PROJECT_FORM_INSTANCE } from '@constants/index';
const { PROCEDURE } = PROJECT_FORM_INSTANCE;

export const updateIndexes = (fields) =>
  fields.map((field, index) => ({ ...field, index }));

export const createSection = (properties) => ({
  type: DRAG_TYPES.SECTION,
  fields: [],
  ...properties,
});

export const createProcedure = (properties) => ({
  type: DRAG_TYPES.PROCEDURE,
  fields: [
    [{
      id: uuidv4(),
      index: 0,
      type: FIELD_TYPES.CLIENT_SITUATIONS,
      width: 100
    }]
  ],
  ...properties,
});

export const updateSection = (sectionList, id, newValues) => {
  let updatedSection = null;
  const sectionIndex = sectionList.findIndex((section) => section.id === id);
  if (sectionIndex >= 0) {
    const copyOldSection = copyObject(sectionList[sectionIndex]);
    updatedSection = {
      index: sectionIndex,
      value: {
        ...newValues,
        id: copyOldSection.id,
        index: copyOldSection.index,
        fields: copyOldSection.fields,
        type: copyOldSection.type,
      },
    };
  }
  return updatedSection;
};

const generateQuestionRules = (sectionId, field, dispatchSchema) => {
  const {rules, id} = field;
  if(!rules) return;
  const {QUESTION, INDUSTRY, REPORTING_FRAMEWORK, AUDIT_AREA, SCOTABDS, PERFORMANCE_STANDARDS} = RULES.CRITERIA_TYPES;
  const rulesObject = {
    QuestionId: id,
    SectionId: sectionId,
    Behavior: rules.behavior,
    MatchType: rules.match,
    FormRuleCriterias: rules.criterias.map(c=>({
      RuleCriteriaTypeId: c.criteriaTypeId ?? defaultCriteriaTypes.find(d => d.value === c.criteriaType)?.id,
      QuestionId: c.questionId?.length ? c.questionId : null,
      CriteriaOperator:   c.isEqual === 'any' ? 'Is Any' : c.isEqual ? 'Is' : 'Is Not',
      IsExternalQuestion: c.isExternalQuestion,
      FormRuleCriteriaAnswers: QUESTION === c.criteriaType? c.value?.map(val=> ({Answer: val})) : null,
      FormRuleCriteriaMethodologyIndustries: INDUSTRY === c.criteriaType ? c.value?.map(val=> ({MethodologyIndustryId: val})) : null,
      FormRuleCriteriaFrameworks: REPORTING_FRAMEWORK === c.criteriaType ? c.value?.map(val=> ({FrameworkId: val})) : null,
      FormRuleCriteriaAuditAreas: AUDIT_AREA === c.criteriaType ? c.value?.map(val=> ({AuditAreaId: val})) : null,
      FormRuleCriteriaSCOTABDs: SCOTABDS === c.criteriaType ? c.value?.map(val=> ({SCOTABDId: val})) : null,
      FormRuleCriteriaPerformanceStandards: PERFORMANCE_STANDARDS === c.criteriaType ? c.value?.map(val=> ({PerformanceStandardId: val})) : null
    }))
  };
  dispatchSchema({
    type: SCHEMA_ACTION_TYPES.UPDATE_FORM_RULES,
    questionId: id,
    sectionId: sectionId,
    payload: rulesObject
  });

};

export const updateFormItem = (additionalData, updatedField, schema, dispatchSchema) => {
  if (!(additionalData && updatedField && schema && dispatchSchema)) {
    return;
  }
  if (additionalData?.isQuestionGroupChild) {
    const {
      sectionIndex,
      rowIndex,
      questionGroupIndex,
      questionGroupRowIndex,
      questionGroupFieldIndex,
    } = additionalData;
    const questionGroupFields =
      schema.properties[sectionIndex].fields[rowIndex][questionGroupIndex].fields;
    questionGroupFields[questionGroupRowIndex][questionGroupFieldIndex] = { ...updatedField };
    dispatchSchema({
      type: SCHEMA_ACTION_TYPES.UPDATE_QUESTION_GROUP_FIELDS,
      sectionIndex,
      rowIndex,
      questionGroupIndex,
      payload: questionGroupFields,
    });
  } else {
    const { sectionIndex, rowIndex } = additionalData;
    const section = schema.properties[sectionIndex];
    let updatedFields = [...section?.fields[rowIndex]];
    updatedFields.splice(updatedField.index, 1, updatedField);
    dispatchSchema({
      type: SCHEMA_ACTION_TYPES.UPDATE_FIELDS,
      index: sectionIndex,
      rowIndex: rowIndex,
      payload: updatedFields,
    });
    generateQuestionRules(section?.id, updatedField, dispatchSchema);
  }
};

export const checkQuillTextHasValue = (value) => {
  if (value instanceof Array) {
    return value.length > 0;
  } else {
    return value.trim() === '<p><br></p>' ? false : !!value?.trim();
  }
};

export const convertStringToArray = (text) => text.split('\n').filter((i) => i);

export const convertArrayToStringOptions = (array) => array.join('\n');

export const hasDuplicateItems = (list) =>
  list.some((val, i) => list.indexOf(val) !== i);

export const movePropertyByIndex = (properties, currentIndex, newIndex) => {
  const newProperties = [...properties];
  const newProperty = newProperties[currentIndex];
  const isPlanningAnalytics = newProperties[newIndex]?.formTypeId === PLANNING_ANALYTICS_FORM_TYPE_ID && newProperties[newIndex]?.type === DRAG_TYPES.SECTION_CUSTOM;
  if (isPlanningAnalytics) return newProperties;

  newProperties.splice(currentIndex, 1);
  newProperties.splice(newIndex, 0, newProperty);

  const finalNewProperties = updateIndexes(newProperties);

  return finalNewProperties;
};

export const dropNewItem = (additionalData, newField, trigger, schema, dispatchSchema) => {
  if (!(additionalData && newField && trigger && schema && dispatchSchema)) {
    return;
  }
  const { sectionIndex, rowIndex, index: fieldIndex } = additionalData.refField;
  const section = schema.properties[sectionIndex];
  const fields = section?.fields[rowIndex];
  if (trigger === TRIGGERS.DROPZONE) {
    const oldIndex = fieldIndex;
    const newFields = fields;
    if (
      additionalData.position === DROPZONE_AREAS.LEFT ||
      additionalData.position === DROPZONE_AREAS.RIGHT
    ) {
      newFields.splice(
        additionalData.position === DROPZONE_AREAS.LEFT ? oldIndex : oldIndex + 1,
        0,
        newField
      );
      const finalNewFields = updateIndexes(newFields);
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_FIELDS,
        index: sectionIndex,
        rowIndex: rowIndex,
        payload: finalNewFields,
      });
    } else if (
      additionalData.position === DROPZONE_AREAS.TOP ||
      additionalData.position === DROPZONE_AREAS.BOTTOM
    ) {
      let newProperties = schema.properties[sectionIndex]?.fields;
      const finalNewRow = updateIndexes([newField]);
      newProperties.splice(
        additionalData.position === DROPZONE_AREAS.TOP ? rowIndex : rowIndex + 1,
        0,
        finalNewRow
      );
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS,
        index: sectionIndex,
        payload: newProperties,
      });
    }
  } else if (trigger === TRIGGERS.INNER_SECTION) {
    newField.index = 0;
    const newFields = [newField];
    dispatchSchema({
      type: SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS,
      index: additionalData.refField?.sectionIndex,
      payload: [newFields],
    });
  } else if (trigger === TRIGGERS.QUESTION_GROUP) {
    const { questionGroupIndex } = additionalData.refField;
    const questionGroupFields = fields[questionGroupIndex].fields;
    newField.index = 0;
    questionGroupFields.push([newField]);
    dispatchSchema({
      type: SCHEMA_ACTION_TYPES.UPDATE_QUESTION_GROUP_FIELDS,
      sectionIndex,
      rowIndex,
      questionGroupIndex,
      payload: questionGroupFields,
    });
  } else if (trigger === TRIGGERS.QUESTION_GROUP_DROPZONE) {
    const { questionGroupIndex, questionGroupRowIndex, questionGroupFieldIndex } =
      additionalData.refField;
    const questionGroupFields = fields[questionGroupIndex].fields;
    if (
      additionalData.position === DROPZONE_AREAS.LEFT ||
      additionalData.position === DROPZONE_AREAS.RIGHT
    ) {
      const newFields = questionGroupFields[questionGroupRowIndex];
      newFields.splice(
        additionalData.position === DROPZONE_AREAS.LEFT
          ? questionGroupFieldIndex
          : questionGroupFieldIndex + 1,
        0,
        newField
      );
      const finalNewFields = updateIndexes(newFields);
      questionGroupFields[questionGroupRowIndex] = [...finalNewFields];
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_QUESTION_GROUP_FIELDS,
        sectionIndex,
        rowIndex,
        questionGroupIndex,
        payload: questionGroupFields,
      });
    } else if (
      additionalData.position === DROPZONE_AREAS.TOP ||
      additionalData.position === DROPZONE_AREAS.BOTTOM
    ) {
      const finalNewRow = updateIndexes([newField]);
      questionGroupFields.splice(
        additionalData.position === DROPZONE_AREAS.TOP
          ? questionGroupRowIndex
          : questionGroupRowIndex + 1,
        0,
        finalNewRow
      );
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_QUESTION_GROUP_FIELDS,
        sectionIndex,
        rowIndex,
        questionGroupIndex,
        payload: questionGroupFields,
      });
    }
  }
  if (trigger === TRIGGERS.DROPZONE || trigger === TRIGGERS.INNER_SECTION)
    generateQuestionRules(section?.id, newField, dispatchSchema);
};

const generateRulesObject = (questionId, sectionId, rules) => {
  const { QUESTION, INDUSTRY, REPORTING_FRAMEWORK, AUDIT_AREA, SCOTABDS, PERFORMANCE_STANDARDS } = RULES.CRITERIA_TYPES;
  return {
    QuestionId: questionId,
    SectionId: sectionId,
    Behavior: rules.behavior,
    MatchType: rules.match,
    FormRuleCriterias: rules.criterias.map(c => ({
      RuleCriteriaTypeId: c.criteriaTypeId,
      QuestionId: c.questionId.length ? c.questionId : null,
      CriteriaOperator: c.isEqual === 'any' ? 'Is Any' : c.isEqual ? 'Is' : 'Is Not',
      IsExternalQuestion: c.isExternalQuestion,
      FormRuleCriteriaAnswers: QUESTION === c.criteriaType ? c.value?.map(val => ({ Answer: val })) : null,
      FormRuleCriteriaMethodologyIndustries: INDUSTRY === c.criteriaType ? c.value?.map(val => ({ MethodologyIndustryId: val })) : null,
      FormRuleCriteriaFrameworks: REPORTING_FRAMEWORK === c.criteriaType ? c.value?.map(val => ({ FrameworkId: val })) : null,
      FormRuleCriteriaAuditAreas: AUDIT_AREA === c.criteriaType ? c.value?.map(val => ({ AuditAreaId: val })) : null,
      FormRuleCriteriaSCOTABDs: SCOTABDS === c.criteriaType ? c.value?.map(val => ({ SCOTABDId: val })) : null,
      FormRuleCriteriaPerformanceStandards: PERFORMANCE_STANDARDS === c.criteriaType ? c.value?.map(val => ({ PerformanceStandardId: val })) : null
    }))
  };
};

export const generateSectionRules = (section, dispatchSchema) => {
  const {rules, id} = section;
  if(!rules) return;
  const rulesObject = generateRulesObject(null, id, rules);
  dispatchSchema({
    type: SCHEMA_ACTION_TYPES.UPDATE_FORM_RULES,
    questionId: null,
    sectionId: id,
    payload: rulesObject
  });
}

export const generateFormProcedure = (section, dispatchSchema) => {
  const { fields } = section;
  const procedureIds = fields?.flatMap(row=> (row?.map(field=> field.id)))
  const procedureObject = {
    ProcedureComponentId: procedureIds.length ? procedureIds[0] : null,
    SectionId: section.id,
    AuditAreaId: section.auditArea
  };
  dispatchSchema({
    type: SCHEMA_ACTION_TYPES.UPDATE_FORM_PROCEDURES,
    sectionId: section.id,
    payload: procedureObject
  });
}

/// This is copied from database and being used to support old implementation wherein there is no criteria type id in a schema. (BUG 101496)
export const defaultCriteriaTypes = [
  {
    id: 2,
    label: 'Industry',
    value: RULES.CRITERIA_TYPES.INDUSTRY,
  },
  {
    id: 5,
    label: 'Audit Area',
    value: RULES.CRITERIA_TYPES.AUDIT_AREA,
  },
  {
    id: 6,
    label: 'SCOTABDs',
    value: RULES.CRITERIA_TYPES.SCOTABDS,
  },
  {
    id: 3,
    label: 'Reporting Framework',
    value: RULES.CRITERIA_TYPES.REPORTING_FRAMEWORK,
  },
  {
    id: 4,
    label: 'Performance Standards',
    value: RULES.CRITERIA_TYPES.PERFORMANCE_STANDARDS,
  },
  {
    id: 1,
    label: 'Select a question',
    value: RULES.CRITERIA_TYPES.QUESTION,
  },
];
export const generateRuleCriteriaTypes = (ruleCriteriaTypes) => {

return defaultCriteriaTypes.map(def => {
  const found = ruleCriteriaTypes?.find(rule=> rule.RuleCriteriaTypeId === def.id && !!rule.IsEnabled)
  if (found)
    return {
      id: found.RuleCriteriaTypeId,
      label: def.label,
      value: def.value,
      name: found.RuleCriteriaTypeName,
      isEnabled: found.IsEnabled,
      displayOrder: found.DisplayOrder
    }
  else return null
}).filter(i=> i || i?.isEnabled);
}

const renderExistingQuestion = (
  newFieldDialogOpen,
  setNewFieldDialogOpen,
  additionalData,
  trigger
) => <ExistingQuestionDialog
    visible={newFieldDialogOpen}
    setVisible={setNewFieldDialogOpen}
    additionalData={additionalData}
    trigger={trigger}
  />

export const dialogSetup = (
  newFieldDialogOpen,
  setNewFieldDialogOpen,
  additionalData,
  trigger
) => {
  if (additionalData?.existingQuestionId && additionalData?.linkType)
    return renderExistingQuestion(
      newFieldDialogOpen,
      setNewFieldDialogOpen,
      additionalData,
      trigger
    );
  switch (additionalData?.type) {
    case FIELD_TYPES.CHECKBOXGROUP:
      return (
        <CheckboxDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.RADIOGROUP:
      return (
        <RadioButtonDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.FREETEXT:
    case FIELD_TYPES.FREETEXT_NO_RULES:
      return (
        <FreeTextDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={{
            ...additionalData,
            fieldType: additionalData?.type
          }}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.SHORT_ANSWER:
      return (
        <ShortAnswerDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.LONG_ANSWER:
      return (
        <LongAnswerDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.DROPDOWN:
      return (
        <DropDownDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.DATE_PICKER:
      return (
        <DatePickerDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.TABLE:
      return (
        <TableDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.EXISTING_QUESTION:
      return renderExistingQuestion(
        newFieldDialogOpen,
        setNewFieldDialogOpen,
        additionalData,
        trigger
      );
    case FIELD_TYPES.FORMULA:
      return (
        <FormulaDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    case FIELD_TYPES.QUESTION_GROUP:
      return (
        <QuestionGroupDialog
          visible={newFieldDialogOpen}
          setVisible={setNewFieldDialogOpen}
          additionalData={additionalData}
          trigger={trigger}
        />
      );
    default:
      return null;
  }
};

// TODO: Add identifier for field type
export const createField = (properties) => ({
  type: DRAG_TYPES.FIELD,
  ...properties,
});

export const widthOptions = [
  {
    label: '25%',
    value: 25,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '75%',
    value: 75,
  },
  {
    label: '100%',
    value: 100,
  },
];

export const getQuestionLocationInSchema = (properties, questionId) => {
  const location = properties.flatMap(section => {
    return section?.fields?.map((row, rowIndex) => {
      const colIndex = row.findIndex(question => question.id === questionId);
      if (colIndex >= 0) {
        return { section, rowIndex, colIndex };
      } else return null;
    });
  }).filter(i => !!i);
  if (location?.length <= 0) {
    return {};
  }

  return location[0];
}

export const deleteQuestionFromFields = (fields, rowIndex, colIndex) => {
  const newFields = [...fields];
  newFields[rowIndex].splice(colIndex, 1);
  if (newFields[rowIndex].length <= 0) {
    newFields.splice(rowIndex, 1);
  }

  return newFields;
}

export const deleteFormrules = (formRules, questionId) => {
  const filteredFormRules = formRules.filter(rule => rule?.QuestionId?.toLowerCase() !== questionId.toLowerCase());
  return filteredFormRules;
}

const isQuestionUsedInARule = (properties, questionId, sectionId) => {

  let result = false;
  properties.forEach(section => {
    if (sectionId && section.id === sectionId) {
    }
    else {
      section.fields.forEach(rows => {
        rows.forEach(column => {
          if (column.rules?.criterias) {
            column.rules.criterias.forEach(c => {
              if (c.questionId === questionId) {
                result = true;
              }
            })
          }
        });

      })
    }

  });

  return result;
}

const isQuestionUsedInASectionRule = (properties, questionId, sectionId) => {
  let result = false;
  properties.forEach(section => {
    if (sectionId && section.id === sectionId) {
    }
    else {
      if (section.rules?.criterias) {
        section.rules.criterias.forEach(c => {
          if (c.questionId === questionId) {
            result = true;
          }
        })
      }
    }

  });

  return result;
}

const isQuestionUsedAsAnswer = (properties, questionId, sectionId) => {
  let result = false;
  properties.forEach(section => {
    if (sectionId && section.id === sectionId) {
    }
    else {
      section.fields.forEach(rows => {
        rows.forEach(column => {
          if (column.existingQuestionId && column.existingQuestionId === questionId && column.linkType !== "Duplicate Question") {
            result = true;
          }
        })
      })
    }

  });

  return result;
}

export const performLocalSchemaDeleteValidation = (properties, questionId, formRules) => {

  const propertiesCopy = copyArray(properties);
  const formRulesCopy = copyArray(formRules);


  if (isQuestionUsedInARule(propertiesCopy, questionId) || isQuestionUsedInASectionRule(propertiesCopy, questionId)) {
    return { fields: [], message: DELETE_QUESTION_ERROR.USED_IN_RULE }
  }
  else if (isQuestionUsedAsAnswer(propertiesCopy, questionId)) {
    return { fields: [], message: DELETE_QUESTION_ERROR.USED_AS_ANSWER }
  }
  else {
    const { section, rowIndex, colIndex } = getQuestionLocationInSchema(propertiesCopy, questionId);
    const newFields = deleteQuestionFromFields(section.fields, rowIndex, colIndex);
    const newFormRules = deleteFormrules(formRulesCopy, questionId);
    const sectionIndex = propertiesCopy.findIndex(f => f.id === section.id);
    return { index: sectionIndex, fields: newFields, message: '', formRules: newFormRules, section: section }
  }
}

export const performLocalSectionDeleteValidation = (properties, sectionId, formRules) => {
  const section = properties.find(p => p.id === sectionId);
  let canBeDeleted = true;
  if (section) {
    const questions = [].concat(...section.fields);
    for (let i = 0; i < questions.length; i++) {
      const questionId = questions[i].id;
      if (isQuestionUsedInARule(properties, questionId, sectionId) || isQuestionUsedInASectionRule(properties, questionId, sectionId)) {
        canBeDeleted = false;
        break;
      }
      if (isQuestionUsedAsAnswer(properties, questionId, sectionId)) {
        canBeDeleted = false;
        break;
      }
    }
  }
  if (canBeDeleted) {
    const newProperties = copyArray(properties);
    const newFormRules = copyArray(formRules);
    const sectionIndex = newProperties.findIndex(p => p.id === sectionId);
    newProperties.splice(sectionIndex, 1);
    newFormRules.splice(0, newFormRules.length)
    return { canBeDeleted, newProperties, newFormRules }
  }
  return { canBeDeleted, newProperties: null }
}

export const filterEmptyCriterias = (rules) => {
  const copy = { ...rules };
  const filtered = copy.criterias.filter((criteria) => criteria.value.length);
  copy.criterias = filtered;
  return copy;
};

export const hideOrDisableComponent = (schema) => {
  const {
    READY_RELEASE
  } = FORMSLIBRARY.EN.PROJECT_TABLE.GRID_CAPTIONS;

  const {
    MethodologyVersionStatus
  } = FORM_SETTINGS.EN;


  if (schema?.formSettings) {
    const { formStatusName, methodologyVersionStatusId } = schema.formSettings;

    return formStatusName === READY_RELEASE || methodologyVersionStatusId === MethodologyVersionStatus.Released;
  }
  else {
    return false;
  }
}

const restructureStepParent = (procedureId, parentid, array, originalSummaryProcedures) => {
  const lookupSummaryProcedure = originalSummaryProcedures?.find((x) => x.SummaryProcedureId === procedureId);
  const parentSummaryProcedureStep = lookupSummaryProcedure?.SummaryProcedureSteps.find((x) => parentid === x.SummaryProcedureStepId);
  const IsOnFilteredSteps = array.find((x) => x.SummaryProcedureStepId === parentSummaryProcedureStep?.SummaryProcedureStepId);

  if (IsOnFilteredSteps || !parentSummaryProcedureStep || parentSummaryProcedureStep?.ParentSummaryProcedureStepId === null) {
    return IsOnFilteredSteps ? IsOnFilteredSteps.SummaryProcedureStepId : null;
  }

  return restructureStepParent(procedureId, parentSummaryProcedureStep?.ParentSummaryProcedureStepId, array, originalSummaryProcedures)
};

const fixStepsTree = (filteredprocedures, originalSummaryProcedures) => {
  const updatedSteps = filteredprocedures.map((procedure) => {
    return {
      ...procedure,
      SummaryProcedureSteps: procedure.SummaryProcedureSteps.map((smp, index, array) => {
        if (smp.ParentSummaryProcedureStepId) {
          const ParentFound = array.find((step) => step.SummaryProcedureStepId === smp.ParentSummaryProcedureStepId);
          if (ParentFound) {
            return { ...smp, FilteredParentId: smp.ParentSummaryProcedureStepId };
          } else {
            const NewParentId = restructureStepParent(procedure.SummaryProcedureId,
              smp.ParentSummaryProcedureStepId, array, originalSummaryProcedures)
            return { ...smp, FilteredParentId: NewParentId };
          }
        } else {
          return { ...smp, FilteredParentId: null };
        }
      })
    }
  });

  return updatedSteps;
};

export const filterSteps = (filter, summaryProcedures) => {
  let filteredSummaryProcedures;
  let filterFunction;

  switch (filter) {
    case PROCEDURE.FILTER_OPTIONS.OPEN_STEPS:
      filterFunction = (steps) => steps.IsSignOffRequired === true && steps.SignOffUser === null;
      break;

    case PROCEDURE.FILTER_OPTIONS.COMPLETED_STEPS:
      filterFunction = (steps) => steps.IsSignOffRequired === true && steps.SignOffUser !== null;
      break;

    case PROCEDURE.FILTER_OPTIONS.CLIENT_INQUIRY:
      filterFunction = (steps) => steps?.ProcedureNatures?.length > 0 && steps.ProcedureNatures.some((nature) => nature.ProcedureNatureName === PROCEDURE.FILTER_OPTIONS.INQUIRY);
      break;

    case PROCEDURE.FILTER_OPTIONS.PBC_NEEDED:
      filterFunction = (steps) => steps?.ProcedureDependancies?.length > 0 && steps.ProcedureDependancies.some((dependancy) => dependancy.ProcedureDependancyName === PROCEDURE.FILTER_OPTIONS.PBC);
      break;

    case PROCEDURE.FILTER_OPTIONS.CONFIRMATION:
      filterFunction = (steps) => steps?.ProcedureDependancies?.length > 0 && steps.ProcedureDependancies.some((dependancy) => dependancy.ProcedureDependancyName === PROCEDURE.FILTER_OPTIONS.CONFIRM);
      break;

    case PROCEDURE.FILTER_OPTIONS.PLANNING:
      filterFunction = (steps) => steps?.ProcedureTimings?.length > 0 && steps.ProcedureTimings.some((timing) => timing.ProcedureTimingName === PROCEDURE.FILTER_OPTIONS.PLANNING);
      break;

    case PROCEDURE.FILTER_OPTIONS.INTERIM:
      filterFunction = (steps) => steps?.ProcedureTimings?.length > 0 && steps.ProcedureTimings.some((timing) => timing.ProcedureTimingName === PROCEDURE.FILTER_OPTIONS.INTERIM);
      break;

    case PROCEDURE.FILTER_OPTIONS.YEAR_END:
      filterFunction = (steps) => steps?.ProcedureTimings?.length > 0 && steps.ProcedureTimings.some((timing) => timing.ProcedureTimingName === PROCEDURE.FILTER_OPTIONS.YEAR_END);
      break;

    default:
      return summaryProcedures;
  }

  filteredSummaryProcedures = summaryProcedures.map((smp) => {
    return {
      ...smp,
      SummaryProcedureSteps: smp.SummaryProcedureSteps ? smp.SummaryProcedureSteps
        .filter(filterFunction) : []
    }
  });

  return fixStepsTree(filteredSummaryProcedures, summaryProcedures);
}

export const filterCustomProcedures = (filter, customProcedures) => {
  let filteredCustomProcedures;
  let filterFunction;

  switch (filter) {
    case PROCEDURE.FILTER_OPTIONS.OPEN_STEPS:
      filterFunction = (cp) => cp?.SignOffUser === undefined || cp.SignOffUser === null;
      break;

    case PROCEDURE.FILTER_OPTIONS.COMPLETED_STEPS:
      filterFunction = (cp) => cp?.SignOffUser ? cp.SignOffUser !== null : false;
      break;

    default:
      return customProcedures
  }

  filteredCustomProcedures = customProcedures.filter(filterFunction);

  return filteredCustomProcedures;
}

export const isTriggeredFromQuestionGroup = (trigger) => {
  return (
    trigger === TRIGGERS.QUESTION_GROUP ||
    trigger === TRIGGERS.QUESTION_GROUP_DROPZONE ||
    trigger === TRIGGERS.QUESTION_GROUP_EDIT
  );
};

export const validateAdvancedDefaultAnswers = (newValues, currentValues, isMultiple = false) => {
  const emptyIndustries = newValues.some((item) => item.industries.length < 1);
  const withValidValues = newValues?.filter(def => isMultiple ? def.defaultValues?.length > 0 : def.defaultValue?.length);
  const changeToEmptyList = !withValidValues.length && !!currentValues.length;
  return { newValues: withValidValues, isValid: !emptyIndustries || changeToEmptyList };
}

export const getShareableComponentDefaultValue = (additionalData, key, trigger) => {
  if(additionalData.hasOwnProperty(key)) {
    return additionalData[key]
  }
  if(isTriggeredFromQuestionGroup(trigger)) {
    return false
  }
  return true
}
