import { useState, useEffect } from "react"
import {CLASelect} from '@ais/forms';
import { useProjectTrialBalances } from "@services/trialbalance";
import useProjectContext from "@contexts/ProjectContext"
import { useParams } from "react-router-dom";

export const TbSelect = ({ value, onChange, defaultValues, isDisabled }) => {
    const { project } = useProjectContext()
    const { projectId } = useParams()
    const { data: balances, isFetching } = useProjectTrialBalances(project?.AppDataInstanceId, projectId)
    const [ trialBalances, setTrialBalances ] = useState([])

    const filterTrialBalances = (trialBalanceData) => {
        const uniqueTrialBalances = [...new Map(trialBalanceData.map(tb => [tb.correlationNameId, tb])).values()]
        const noNullsUniqueTrialBalances = uniqueTrialBalances.filter(
            tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId
        )
        return noNullsUniqueTrialBalances;
    }

    useEffect(() => {
        if(isFetching) {
            return
        }
        if(!balances) {
            return
        }
        const uniqueTbs = filterTrialBalances(balances)
        setTrialBalances(uniqueTbs)
    }, [ balances, isFetching ])

    return (
        <CLASelect
            label="Trial Balance"
            menuItems={trialBalances}
            onChange={onChange}
            value={value}
            defaultValues={defaultValues}
            isDisabled={isDisabled}
        />
    )
}